import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { AnalyticsDataField, AnalyticsLayer } from '@src-v2/components/analytics-layer';
import { Button, Variant } from '@src-v2/components/button-v2';
import { ClampText } from '@src-v2/components/clamp-text';
import { FiltersControls } from '@src-v2/components/filters/inline-control/containers/filters-controls';
import { SvgIcon } from '@src-v2/components/icons';
import { Gutters } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { usePaneState } from '@src-v2/components/panes/pane-context-provider';
import { ResultsCounter } from '@src-v2/components/persistent-search-state/persistent-search-filters';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { SelectMenu } from '@src-v2/components/select-menu';
import { Table } from '@src-v2/components/table/table';
import { FluidTableControls, TableControls } from '@src-v2/components/table/table-addons';
import { Size } from '@src-v2/components/types/enums/size';
import { Light, ListItem } from '@src-v2/components/typography';
import { DataTable as _DataTable } from '@src-v2/containers/data-table/data-table';
import { TablePagination } from '@src-v2/containers/data-table/table-pagination';
import { FindingPane } from '@src-v2/containers/finding-pane/finding-pane';
import { UploadManualFindingReportModal } from '@src-v2/containers/manual-findings/upload-manual-findings-report-modal';
import {
  DiscoveryDateCell,
  FindingComponentCell,
} from '@src-v2/containers/risks/risks-common-cells';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { useDataTable } from '@src-v2/hooks/use-data-table';
import { useFilters } from '@src-v2/hooks/use-filters';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { ManualFindingResponse } from '@src-v2/services';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { Column } from '@src-v2/types/table';
import { dataAttr, stopPropagation } from '@src-v2/utils/dom-utils';
import { FindingRowActionCell } from './finding-row-actions';

export const ManualFindingsPage = observer(() => {
  const { findings, rbac, application } = useInject();
  const history = useHistory();
  const {
    activeFilters: { ...filters },
  } = useFilters();
  const [modalElement, setModal, closeModal] = useModalState();

  const filterOptions = [];

  const dataModel = useDataTable(findings.searchManualFindings, {
    columns: tableColumns,
    searchParams: { filters },
  });

  const canWrite = rbac.canEdit(resourceTypes.ManualFindings);
  const isNewSettingsLayout = application.isFeatureEnabled(FeatureFlag.SettingsNewLayout);

  const handleOpenModal = () => {
    setModal(<UploadManualFindingReportModal onClose={closeModal} onSubmit={() => {}} />);
  };

  return (
    <AnalyticsLayer analyticsData={{ [AnalyticsDataField.Context]: 'Settings - Manual Findings' }}>
      <Page title="Manual Findings">
        <Gutters>
          <ManualFindingTableControls data-narrow={dataAttr(!filterOptions?.length)}>
            {/*<TableSearch placeholder="Search by finding name..." />*/}
            {Boolean(filterOptions?.length) && (
              <TableControls.Filters>
                <FiltersControls filterOptions={filterOptions} />
              </TableControls.Filters>
            )}
            <TableControls.Counter>
              <ResultsCounter
                count={dataModel.searchState.count}
                total={dataModel.searchState.total}
                itemName="findings"
              />
            </TableControls.Counter>
            {application.isFeatureEnabled(FeatureFlag.UploadCSVReport) ? (
              <SelectMenu
                appendTo="parent"
                variant={Variant.PRIMARY}
                placeholder="Add finding"
                onClick={stopPropagation}
                onItemClick={stopPropagation}>
                <AddFindingListItem>
                  <SelectMenu.Label
                    disabled={!canWrite}
                    onClick={() =>
                      history.push(
                        isNewSettingsLayout
                          ? '/manual-findings/create'
                          : '/settings/manual-findings/create'
                      )
                    }>
                    <SvgIcon name="Edit" />
                    <Light>Create finding</Light>
                  </SelectMenu.Label>
                </AddFindingListItem>
                <AddFindingListItem>
                  <SelectMenu.Label disabled={!canWrite} onClick={handleOpenModal}>
                    <SvgIcon name="UploadFile" />
                    <Light>Upload report</Light>
                  </SelectMenu.Label>
                </AddFindingListItem>
              </SelectMenu>
            ) : (
              <CreateFindingButton
                disabled={!canWrite}
                variant={Variant.PRIMARY}
                onClick={() =>
                  history.push(
                    isNewSettingsLayout
                      ? '/manual-findings/create'
                      : '/settings/manual-findings/create'
                  )
                }>
                Create finding
              </CreateFindingButton>
            )}
          </ManualFindingTableControls>

          <DataTable expandable dataModel={dataModel}>
            {item => <FindingRow key={item.key} data={item} />}
          </DataTable>

          {!dataModel.ignorePagination && dataModel.searchState.items.length > 0 && (
            <TablePagination searchState={dataModel.searchState} />
          )}
        </Gutters>
        {modalElement}
      </Page>
    </AnalyticsLayer>
  );
});

const CreateFindingButton = styled(Button)`
  width: fit-content;
`;

const DataTable = styled(_DataTable)`
  ${Table.Body} ${Table.Row} {
    height: 14rem;
  }

  ${Table.Cell}:last-child {
    padding-right: 2rem;
  }
`;

const AddFindingListItem = styled(ListItem)`
  ${SelectMenu.Label} {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem 2rem;
    border-radius: 2rem;

    &:hover {
      background-color: var(--color-blue-gray-15);
    }
  }
`;

const ManualFindingTableControls = styled(FluidTableControls)`
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-end;

  &[data-narrow] ${TableControls.Filters} {
    grid-area: actions;
  }
`;

const FindingRow = ({ data, ...props }: { data: any }) => {
  const { pushPane } = usePaneState();

  const openPane = useCallback(() => {
    pushPane(<FindingPane findingDataModelReference={data.findingDataModelReference} />);
  }, [pushPane]);

  return <DataTable.Row {...props} data={data} onClick={openPane} />;
};
export const tableColumns: Column<ManualFindingResponse>[] = [
  {
    key: 'finding-severity',
    label: 'Severity',
    width: '28rem',
    resizeable: false,
    sortable: true,
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <RiskIcon riskLevel={data.severity} size={Size.XSMALL} />
      </Table.Cell>
    ),
  },
  {
    key: 'finding-name',
    label: 'Finding name',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <ClampText>{data.name}</ClampText>
      </Table.Cell>
    ),
  },
  {
    key: 'finding-status',
    label: 'Finding status',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <ClampText>{data.status}</ClampText>
      </Table.Cell>
    ),
  },
  {
    key: 'discovered-on',
    fieldName: 'Discovered',
    label: 'Discovered on',
    minWidth: '36rem',
    sortable: true,
    Cell: DiscoveryDateCell,
  },
  {
    key: 'component',
    label: 'Component',
    sortable: true,
    Cell: FindingComponentCell,
  },
  {
    key: 'actions-menu',
    label: '',
    width: '10rem',
    draggable: false,
    resizeable: false,
    Cell: FindingRowActionCell,
  },
];

export default ManualFindingsPage;
