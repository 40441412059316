import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Banner } from '@src-v2/components/banner';
import { Button } from '@src-v2/components/button-v2';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';

export const DefinitionBanner = ({
  setRule,
  addRule,
  setOpenedCreateRuleFromBanner,
  rule: { definitionType, definitionName },
}) => {
  const history = useHistory();
  const titlePrefix = addInterpunctSeparator(definitionType, definitionName);

  const handleAction = useCallback(() => {
    history.push('/governance/rules');
    setOpenedCreateRuleFromBanner(true);
    addRule();
  }, [setOpenedCreateRuleFromBanner, addRule]);

  const handleClose = useCallback(() => setRule({}), [setRule]);

  return (
    <Banner
      icon="Success"
      title={`${titlePrefix} was successfully created!`}
      description="Now let's create a governance policy to define and manage the risk"
      onClose={handleClose}>
      <Button onClick={handleAction} size="small">
        Create policy
      </Button>
    </Banner>
  );
};
