import _ from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Button, TextButton, Variant } from '@src-v2/components/button-v2';
import { VendorCircle } from '@src-v2/components/circles';
import { SearchFilterInput } from '@src-v2/components/forms/search-input';
import { SvgIcon } from '@src-v2/components/icons';
import { ErrorLayout } from '@src-v2/components/layout';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Size } from '@src-v2/components/types/enums/size';
import { Heading4, SubHeading4 } from '@src-v2/components/typography';
import { getProviderGroupState } from '@src-v2/containers/connectors/connections/cards/default-card';
import { SearchControls } from '@src-v2/containers/connectors/connections/catalog/connectors-catalog';
import { ConnectionRow } from '@src-v2/containers/connectors/management/connection-row';
import {
  getFilteredServers,
  getProviderTypesForServer,
} from '@src-v2/containers/connectors/management/manage-connections';
import { ServerModal } from '@src-v2/containers/connectors/server-modal/server-modal';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useFilters } from '@src-v2/hooks/use-filters';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { Connection } from '@src-v2/types/connector/connectors';
import { ProviderGroupType } from '@src-v2/types/enums/provider-group-type';
import { ApiProviderGroup } from '@src-v2/types/providers/api-provider-group';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { ProviderGroup } from '@src/src-v2/types/enums/provider-group';

export const ManageSingleConnection = observer(props => {
  const { hash } = useLocation();
  const history = useHistory();
  const params: { key?: string } = useParams();
  const { connectors } = useInject();
  const { activeFilters } = useFilters();
  const providerTypes = useSuspense(connectors.getProviderTypes);
  const [modalElement, setModal, closeModal] = useModalState();

  const hasHash = hash?.length > 1; // hash can be # so we check for bigger string

  const providerGroupByKey = useMemo(
    () =>
      providerTypes
        .flatMap(provider => provider.providerGroups)
        .find(
          (provider: ProviderGroup | ApiProviderGroup) =>
            provider.key?.toLowerCase() === params?.key?.toLowerCase()
        ),
    [providerTypes]
  );

  const [currentProviderGroupByKey, setCurrentProvideGroupByKey] = useState(providerGroupByKey);
  const providerGroupState = getProviderGroupState({ provider: currentProviderGroupByKey });

  const openConnectModal = useCallback(
    () => setModal(<ServerModal providerGroup={providerGroupByKey} onClose={closeModal} />),
    [providerGroupByKey, setModal, closeModal]
  );

  // this is default providerType for connectionRow props
  const defaultProviderType = useMemo(() => {
    if (!_.isNil(providerGroupByKey.typeOverride)) {
      return providerTypes.find(provider => provider.key === providerGroupByKey.typeOverride);
    }

    if (hasHash) {
      return providerTypes.find(
        provider => provider.key?.toLowerCase() === hash.slice(1).toLowerCase()
      );
    }

    return providerTypes.find(provider =>
      provider.providerGroups.some(group => group.key === providerGroupByKey.key)
    );
  }, [providerTypes, providerGroupByKey, hash]);

  useEffect(() => {
    if (activeFilters.searchTerm) {
      if (providerGroupByKey) {
        const customServers = getFilteredServers(
          activeFilters.searchTerm,
          providerGroupByKey.servers
        );
        setCurrentProvideGroupByKey({ ...providerGroupByKey, servers: customServers });
      }
    } else {
      setCurrentProvideGroupByKey(providerGroupByKey);
    }
  }, [activeFilters.searchTerm, providerGroupByKey]);

  if (!providerGroupByKey?.connected) {
    history.push('/connectors/manage');
  }

  return (
    <>
      <SearchControls>
        <SearchFilterInput placeholder="Search by name" defaultValue={activeFilters.searchTerm} />
        {providerGroupByKey?.types.hasOwnProperty?.(ProviderGroupType.SourceCode) && (
          <TextButton
            to={{
              pathname: '/connectors/manage/repositories',
              state: { isSingleConnection: true, isAllRepositories: true },
            }}>
            View all repositories <SvgIcon name="Arrow" size={Size.XSMALL} />
          </TextButton>
        )}
      </SearchControls>
      <SingleProvider {...props}>
        <Header>
          <DescriptionContainer>
            <Tooltip content={providerGroupState.tooltip} disabled={!providerGroupState.tooltip}>
              <VendorCircle
                name={currentProviderGroupByKey?.key}
                size={Size.XXLARGE}
                state={providerGroupState.type}
              />
            </Tooltip>
            <Title>
              <Heading4>
                {currentProviderGroupByKey?.displayName ?? currentProviderGroupByKey?.key}
              </Heading4>
              <SubHeading4>
                {addInterpunctSeparator(
                  ...getProviderTypesForServer(currentProviderGroupByKey?.key, providerTypes)
                )}
              </SubHeading4>
            </Title>
          </DescriptionContainer>
          <Actions>
            {providerGroupByKey?.docsUrl && (
              <Button
                href={providerGroupByKey?.docsUrl}
                variant={Variant.TERTIARY}
                endIcon="External">
                Documentation
              </Button>
            )}
            <Button onClick={openConnectModal} size={Size.LARGE} variant={Variant.SECONDARY}>
              Add another
            </Button>
          </Actions>
        </Header>
        <OptionsList>
          {currentProviderGroupByKey?.servers?.length > 0 ? (
            currentProviderGroupByKey?.servers?.map((server: Connection) => (
              <ConnectionRow
                key={server.url}
                providerRouteUrl={defaultProviderType.providerRouteUrl}
                providerKey={defaultProviderType.key}
                server={server}
                manageable={defaultProviderType.manageable}
                disabled={!defaultProviderType.editable}
                monitorEnabled={defaultProviderType.monitorEnabled}
                isSingleConnection
              />
            ))
          ) : (
            <ErrorLayout.NoResults />
          )}
        </OptionsList>
      </SingleProvider>
      {modalElement}
    </>
  );
});

const ProvidersContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
`;

const SingleProvider = styled(ProvidersContainer)`
  gap: 4rem;
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const DescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const OptionsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;

const Actions = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;

  ${Button} {
    &[data-variant=${Variant.TERTIARY}] {
      background-color: transparent;

      &:hover {
        background-color: var(--color-blue-gray-20);
      }
    }
  }
`;
