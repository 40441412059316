import _ from 'lodash';
import { Paragraph, Strong } from '@src-v2/components/typography';
import {
  CodeReference,
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesContainer,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

const InputValidationModificationsDetails = ({ modifications }) => (
  <MaterialChangesContainer>
    {modifications.map(({ isNewParameter, isValidated, parameterName, isDeleted }) => (
      <MaterialChangesRow key={parameterName}>
        <Strong>{parameterName}</Strong>-
        {isNewParameter ? (
          <Paragraph>added without validation</Paragraph>
        ) : isDeleted ? (
          <Paragraph>previously unvalidated parameter was removed</Paragraph>
        ) : (
          <Paragraph>validation was {isValidated ? 'added' : 'removed'}</Paragraph>
        )}
      </MaterialChangesRow>
    ))}
  </MaterialChangesContainer>
);

export function InputValidationMaterialChange({
  materialChange,
  thenSubType,
  repository,
  commitSha,
  relativeFilePath,
  lineNumber,
  whenValue,
}) {
  const { parameterNames, unvalidatedParameters } = materialChange.violation;
  const { className, methodName, apiName } = materialChange.codeReference;
  console.log('thenSubTypeToVerb(thenSubType)', thenSubTypeToVerb(thenSubType));
  return (
    <>
      <MaterialChangesRow>
        <Paragraph>Missing input validation {thenSubTypeToVerb(thenSubType)} for API</Paragraph>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}>
          {apiName ?? methodName}
        </CommitCodeReference>
        <Paragraph>of class {className},</Paragraph>
        {whenValue !== 'any' && <Paragraph>under {whenValue},</Paragraph>}
        {thenSubType === 'Removed' ? (
          <Paragraph>for all parameters</Paragraph>
        ) : (
          <Paragraph>for the following parameters:</Paragraph>
        )}
      </MaterialChangesRow>
      <MaterialChangesRow>
        {thenSubType === 'Altered' && (
          <InputValidationModificationsDetails modifications={materialChange.modifications} />
        )}
        {thenSubType === 'Added' && (
          <InputValidationDescription
            repository={repository}
            relativeFilePath={relativeFilePath}
            lineNumber={lineNumber}
            methodDisplayString={materialChange.codeReference.methodSignature}
            parameterNames={parameterNames}
            unvalidatedParameters={unvalidatedParameters}
          />
        )}
      </MaterialChangesRow>
    </>
  );
}

const ParametersUnvalidated = ({ parameterNames }) =>
  parameterNames.map(parameterName => (
    <MaterialChangesRow key={parameterName}>
      <Strong>{parameterName}</Strong>
    </MaterialChangesRow>
  ));

const ParameterTypeUnvalidated = ({ repository, unvalidatedParameter }) => {
  const { parameterName, selfValid, codeReference, unvalidatedTypeFieldsNames } =
    unvalidatedParameter;
  if (!selfValid) {
    return <ParametersUnvalidated parameterNames={[parameterName]} />;
  }

  const allFieldsUnvalidated = _.isEmpty(unvalidatedTypeFieldsNames);

  return (
    <>
      <MaterialChangesRow>
        <Strong>{parameterName}</Strong>
        <Paragraph>-validated on method level, but its type</Paragraph>
        <CodeReference
          repository={repository}
          relativeFilePath={codeReference?.relativeFilePath}
          lineNumber={codeReference?.lineNumber}>
          <Paragraph>{codeReference?.name}</Paragraph>
        </CodeReference>
        <>
          <Paragraph>is not validating </Paragraph>
          {allFieldsUnvalidated ? (
            <Strong small>any of its fields</Strong>
          ) : (
            <>
              <Strong small>{unvalidatedTypeFieldsNames.length}</Strong>
              <Paragraph> of its fields:</Paragraph>
            </>
          )}
        </>
      </MaterialChangesRow>
      {!allFieldsUnvalidated &&
        unvalidatedTypeFieldsNames.map(field => (
          <Paragraph key={field}>
            <Strong>{field}</Strong>
            <Strong>{field}</Strong>
          </Paragraph>
        ))}
    </>
  );
};

const InputValidationDescription = ({
  repository,
  relativeFilePath,
  lineNumber,
  methodDisplayString,
  parameterNames,
  unvalidatedParameters,
}) => (
  <>
    {_.isEmpty(unvalidatedParameters) ? (
      <ParametersUnvalidated parameterNames={parameterNames} />
    ) : (
      unvalidatedParameters.map(unvalidatedParameter => (
        <ParameterTypeUnvalidated
          key={unvalidatedParameter.parameterName}
          repository={repository}
          unvalidatedParameter={unvalidatedParameter}
        />
      ))
    )}
    <CodeReference
      repository={repository}
      relativeFilePath={relativeFilePath}
      lineNumber={lineNumber}>
      <HighlightedCode language="java">{methodDisplayString}</HighlightedCode>
    </CodeReference>
  </>
);
