import _ from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Button, Variant } from '@src-v2/components/button-v2';
import { SelectionCard } from '@src-v2/components/cards/selection-card';
import { FiltersMenu } from '@src-v2/components/filters/menu-control/filters-menu';
import { SearchFilterInput } from '@src-v2/components/forms/search-input';
import { InfiniteScroll } from '@src-v2/components/infinite-scroll';
import { ErrorLayout, Gutters, Section } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { StickyHeader } from '@src-v2/components/layout/sticky-header';
import { UpgradeButton } from '@src-v2/components/marketing/upgrade-button';
import { ResultsCounter } from '@src-v2/components/persistent-search-state/persistent-search-filters';
import { TableControls } from '@src-v2/components/table/table-addons';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { SimpleSelect } from '@src-v2/containers/simple-select';
import { TeamForm } from '@src-v2/containers/teams/team-form';
import { TeamFormAutomated } from '@src-v2/containers/teams/team-form-automated';
import { TeamFormManual } from '@src-v2/containers/teams/team-form-manual';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject, useQueryParams, useSuspense } from '@src-v2/hooks';
import { useFilterSearch, useFilters } from '@src-v2/hooks/use-filters';
import { usePersistentSearchState } from '@src-v2/hooks/use-search-state';
import Consumables from '@src/blocks/ConsumableInfiniteScroll/blocks/Consumables';
import DeveloperPage from '@src/blocks/DeveloperPage';

export default () => (
  <Switch>
    <Route path="/users/contributors/:key">
      <DeveloperPage />
    </Route>

    <Route path="/users/teams/create">
      <Page title="Create Team">
        <CreateSelectionPage />
      </Page>
    </Route>

    <Route path="/users/teams/:key/edit">
      <Page title="Edit Team">
        <TeamEditPage />
      </Page>
    </Route>

    <Route path={['/users/teams', '/users/contributors']}>
      <TeamsSearchPage />
    </Route>
    <TeamsRedirect />
  </Switch>
);

function TeamsSearchPage() {
  const profileType = useProfileType();
  const teamsCount = useTeamsCount();
  const {
    rbac,

    subscription: {
      limitations: { teamsMaxCount },
    },
  } = useInject();

  const navigation = [
    { label: 'Contributors', to: '/users/contributors' },
    { label: 'Teams', to: '/users/teams' },
  ];

  return (
    <Page title={profileType === 'teams' ? 'Teams' : 'Contributors'}>
      <StickyHeader navigation={navigation}>
        {!_.isNil(teamsMaxCount) && teamsCount >= teamsMaxCount ? (
          <UpgradeButton>Create team</UpgradeButton>
        ) : (
          <Button
            to="/users/teams/create"
            disabled={!rbac.canEdit(resourceTypes.Teams)}
            size="large">
            Create team
          </Button>
        )}
      </StickyHeader>
      <AsyncBoundary>
        <TeamsSearchContent />
      </AsyncBoundary>
    </Page>
  );
}

const TeamsSearchContent = observer(({ defaultSort = 'LastActivity' }) => {
  const { profiles } = useInject();
  const profileType = useProfileType();
  const [filterGroups, sortOptions] = useSuspense([
    [profiles.getFilterOptions, { profileType }],
    [profiles.getSortOptions, { profileType }],
  ]);
  const { activeOptions: filterOptions, handleFiltersSearch } = useFilterSearch(filterGroups);
  const { queryParams, updateQueryParams } = useQueryParams();
  const { activeFilters, updateFilters, removeFilters } = useFilters();

  const [searchCounters, setSearchCounters] = useState({ count: null, total: null });

  const handleSearchStateChanged = useCallback(
    searchState => {
      setSearchCounters({ count: searchState.count, total: searchState.total });
    },
    [setSearchCounters]
  );

  return (
    <Gutters>
      <TableControls>
        <SearchFilterInput
          defaultValue={activeFilters?.searchTerm}
          placeholder={`Search ${
            profileType === 'teams' ? 'team name' : 'contributor name or identity'
          }`}
        />
        <TableControls.Actions>
          <ResultsCounter
            count={searchCounters.count}
            total={searchCounters.total}
            itemName={profileType === 'developers' ? 'contributors' : profileType}
          />
          {sortOptions.length > 0 && (
            <SimpleSelect
              title="Select sort criteria"
              options={sortOptions}
              identity={option => option?.label}
              defaultValue={sortOptions.find(
                option => option.key === (queryParams.sort || defaultSort)
              )}
              onSelect={option => updateQueryParams({ sort: option.key })}
            />
          )}
          {filterGroups.length > 0 && (
            <FiltersMenu
              data={filterOptions}
              activeValues={activeFilters}
              autoOpenCount={filterGroups === filterOptions ? 3 : Infinity}
              onChange={updateFilters}
              onSearch={handleFiltersSearch}
              onClear={removeFilters}
              onSave={params => profiles.saveCustomFilter({ ...params, profileType })}
              onRemove={key => profiles.deleteCustomFilter({ profileType, key })}
            />
          )}
        </TableControls.Actions>
      </TableControls>
      <AsyncBoundary>
        <ConsumablesInfiniteScroll
          defaultSort={defaultSort}
          profileType={profileType}
          filterOptions={filterOptions}
          onSearchStateChanged={handleSearchStateChanged}
        />
      </AsyncBoundary>
    </Gutters>
  );
});

const ConsumablesInfiniteScroll = observer(
  ({ filterOptions, profileType, onSearchStateChanged, defaultSort = 'LastActivity' }) => {
    const { profiles } = useInject();
    const { queryParams } = useQueryParams();
    const { activeFilters } = useFilters();

    const { searchTerm, operator, ...filters } = activeFilters;
    const searchState = usePersistentSearchState(profiles.searchProfiles, {
      sort: queryParams.sort || defaultSort,
      profileType,
      searchTerm,
      operator,
      filters,
    });

    useEffect(() => {
      onSearchStateChanged(searchState);
    }, [searchState?.count, searchState?.items]);

    return (
      <InfiniteScroll searchState={searchState}>
        {searchState.count === 0 ? (
          <EmptyContainer>
            <ErrorLayout.NoResults />
          </EmptyContainer>
        ) : (
          <Consumables
            name={profileType}
            profiles={searchState.items}
            filterOptions={filterOptions}
          />
        )}
      </InfiniteScroll>
    );
  }
);

function TeamsRedirect() {
  return <Redirect to="/users/contributors" />;
}

const CreateSelectionPage = observer(() => {
  const indexPage = !useRouteMatch('/users/contributors');
  const {
    history,
    profiles,
    asyncCache,
    application: { integrations },
  } = useInject();

  const handleSubmit = () => {
    if (indexPage) {
      asyncCache.invalidateAll(profiles.searchProfiles);
    } else {
      history.push('/users/contributors');
    }
  };
  return (
    <Switch>
      <Route
        path="/users/teams/create"
        component={() => (
          <>
            <Section>
              <SelectionCard to="/users/teams/create/manual">
                <Heading>Manual</Heading>
                <Paragraph>Manually define the members of the team</Paragraph>
              </SelectionCard>
              <SelectionCard
                to={
                  integrations?.connectedToIdentity && {
                    pathname: '/users/teams/create/automated',
                  }
                }
                disabled={!integrations?.connectedToIdentity}
                button={
                  integrations?.connectedToIdentity ? null : (
                    <Button
                      to="/connectors/connect/identity"
                      endIcon="Arrow"
                      variant={Variant.SECONDARY}>
                      First connect an IDP
                    </Button>
                  )
                }>
                <Heading>Automated</Heading>
                <Paragraph>Pull members from your Identity Provider to define a team</Paragraph>
              </SelectionCard>
            </Section>
          </>
        )}
        exact
      />
      <Route
        path="/users/teams/create/manual"
        component={() => (
          <TeamForm onSubmit={handleSubmit}>
            <TeamFormManual />
          </TeamForm>
        )}
      />
      <Route
        path="/users/teams/create/automated"
        component={() => (
          <TeamForm onSubmit={handleSubmit}>
            <TeamFormAutomated />
          </TeamForm>
        )}
      />
      <TeamsRedirect />
    </Switch>
  );
});

function TeamEditPage() {
  const { state } = useLocation();
  const { profiles, asyncCache } = useInject();
  const handleSubmit = () => asyncCache.invalidateAll(profiles.searchProfiles);

  return (
    <>
      {state?.teamSyncType === 'Automatic' && (
        <TeamForm defaultValues={{ ...state?.defaultValues }} onSubmit={handleSubmit}>
          <TeamFormAutomated editMode />
        </TeamForm>
      )}
      {state?.teamSyncType === 'Manual' && (
        <TeamForm defaultValues={{ ...state?.defaultValues }} onSubmit={handleSubmit}>
          <TeamFormManual editMode />
        </TeamForm>
      )}
    </>
  );
}

const EmptyContainer = styled.div`
  text-align: center;
`;

function useProfileType() {
  return useRouteMatch('/users/contributors') ? 'developers' : 'teams';
}

function useTeamsCount() {
  const { profiles } = useInject();
  // properties order is important! for caching
  const { total } = useSuspense(profiles.searchProfiles, {
    sort: 'LastActivity',
    pageNumber: 0,
    profileType: 'teams',
    filters: {},
  });
  return total;
}
