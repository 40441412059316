import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { InputControl } from '@src-v2/components/forms/form-controls';
import { Paragraph } from '@src-v2/components/typography';
import { RiskLevel, getRiskyRiskLevels } from '@src-v2/types/enums/risk-level';

type RiskScoreControlProps = {
  nameSuffix?: string;
  riskLevel: RiskLevel;
  onChange?: () => void;
};

export const RiskScoreControl = styled(({ namePrefix, ...props }) => (
  <div {...props}>
    {getRiskyRiskLevels()
      .reverse()
      .map(level => (
        <RiskScorePerSeverityControl
          key={level}
          riskLevel={level}
          nameSuffix="RiskWeight"
          name={level.toLowerCase()}
        />
      ))}
  </div>
))`
  width: 114rem;
  display: flex;
  gap: 4rem;
`;

export const RiskScorePerSeverityControl = styled(
  ({
    name: _name,
    nameSuffix,
    riskLevel,
    onChange,
    ...props
  }: RiskScoreControlProps & { name: string }) => {
    const validate = useCallback((input: string) => {
      if (!input) {
        return 'Please enter a positive number.';
      }
      const parsedInput = parseInt(input);
      return parsedInput > 0 || 'Invalid entry';
    }, []);

    const { setValue } = useFormContext();
    const name = `riskScore.${_name}${nameSuffix}`;

    return (
      <div {...props}>
        <Paragraph>{riskLevel}</Paragraph>
        <InputControl
          type="number"
          maxLength={3}
          name={name}
          placeholder="Enter days..."
          onClearClicked={() => setValue(name, 1)}
          rules={{
            validate,
          }}
        />
      </div>
    );
  }
)`
  flex-grow: 1;

  ${Paragraph} {
    margin-bottom: 1rem;
  }
`;
