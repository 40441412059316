import { forwardRef } from 'react';
import styled from 'styled-components';
import ApiiroLogoSmall from '@src-v2/assets/apiiro-logo-small.svg';
import { Badge } from '@src-v2/components/badges';
import { ClampText } from '@src-v2/components/clamp-text';
import { CheckboxToggle } from '@src-v2/components/forms';
import { StatusIcon } from '@src-v2/components/icons';
import { RiskTag } from '@src-v2/components/tags';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { dataAttr, stopPropagation } from '@src-v2/utils/dom-utils';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { workflowsService } from '@src/services/workflowsService';

export default forwardRef(
  (
    {
      rule,
      type,
      prefix,
      formattedName,
      isDisabled,
      isError,
      onDisableToggleClick,
      useTags,
      ...props
    },
    ref
  ) => (
    <Container
      ref={ref}
      {...props}
      data-test-marker="policy-container"
      data-test-id={rule.ordinalId}>
      <FullNameTags>
        <Name
          data-test-marker={`${prefix}-name`}
          data-error={dataAttr(isError)}
          disabled={isDisabled}>
          {isError && <StatusIcon name="Warning" />}
          <ClampText>
            {addInterpunctSeparator(
              `${prefix.toUpperCase()}-${rule.ordinalId}`,
              formattedName || rule.name,
              rule.workflowNotificationEffect ===
                workflowsService.workflowNotificationEffectOptions.periodic &&
                `${rule.workflowPeriod} report`
            )}
          </ClampText>
        </Name>
        {type === 'policy' && rule.isSeed && (
          <Tooltip content="Created by the Apiiro team">
            <ApiiroLogo />
          </Tooltip>
        )}
        {useTags ? (
          <>
            {rule?.risk && rule?.risk !== 'None' && (
              <RiskTag riskLevel={rule.risk.toLowerCase()} size="small">
                {rule.risk}
              </RiskTag>
            )}
            {rule?.tags?.map(tag => (
              <Badge key={tag} color="blue" size="medium">
                {tag}
              </Badge>
            ))}
          </>
        ) : (
          <>
            {rule?.processTags?.map(tag => (
              <Badge key={tag.key} color="blue" size="medium">
                {tag.name}
              </Badge>
            ))}
          </>
        )}

        {rule?.categoryDescription && <Badge size="medium">{rule.categoryDescription}</Badge>}
      </FullNameTags>
      {type === 'policy' && (
        <CheckboxToggle
          checked={!isDisabled}
          onChange={onDisableToggleClick}
          onClick={stopPropagation}
        />
      )}
    </Container>
  )
);

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: hidden;

  ${CheckboxToggle} {
    width: 8rem;
    height: 5rem;
    margin-left: 1rem;

    --checkbox-toggle-thumb-size: 4rem;
    --checkbox-toggle-thumb-padding: 0.5rem;
  }
`;

const Name = styled.span`
  max-width: 100%;
  display: flex;
  font-weight: 600;
  color: ${props => (props.disabled ? 'var(--color-blue-gray-55)' : 'inherit')};
  gap: 2rem;
  overflow-x: hidden;

  &[data-error] {
    color: var(--color-red-45);
  }
`;

const ApiiroLogo = styled(ApiiroLogoSmall)`
  width: 5rem;
  height: 5rem;
  color: var(--color-green-45);
  margin: 0 0.5rem;
`;

const FullNameTags = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  gap: 2rem;
  overflow-x: hidden;
`;
