import { ClampText } from '@src-v2/components/clamp-text';
import { Dropdown } from '@src-v2/components/dropdown';
import { includesValue } from '@src-v2/components/filters/menu-control/filters-menu';
import { ActiveFiltersData, FilterOption } from '@src-v2/hooks/use-filters';

interface RemoteCheckboxItemProps {
  filterKey: string;
  option: FilterOption;
  activeValues: ActiveFiltersData;
  onChange: (option, event) => void;
}

export const RemoteCheckboxItem = ({
  filterKey,
  option,
  activeValues,
  onChange,
}: RemoteCheckboxItemProps) => (
  <Dropdown.CheckboxItem
    checked={includesValue(activeValues?.[filterKey], option.key)}
    onChange={event =>
      onChange?.(
        {
          key: filterKey,
          value: option.key,
          checked: event.target.checked,
          multiple: true,
        },
        event
      )
    }>
    <ClampText>{option.title}</ClampText>
  </Dropdown.CheckboxItem>
);
