import _ from 'lodash';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { DATE_CONSTANTS } from '@src-v2/components/filters/inline-control/components/calendar-date-picker-filter-control';
import { RemoteOptionsGroup } from '@src-v2/components/filters/types';
import { useQueryParams } from '@src-v2/hooks';
import { ActiveFiltersData, Filter } from '@src-v2/hooks/use-filters';
import { makeUrl } from '@src-v2/utils/history-utils';

export const formatRemoteFilterOptions = (filter): RemoteOptionsGroup[] =>
  filter.map(option => ({
    key: option.key,
    title: option.label,
    type: 'remote',
    options: option.results.map(result => ({
      key: result.key,
      title: result.label,
      value: result.label,
    })),
  }));

export const formatFilterOption = option => ({
  key: option.key,
  title: option.label,
  value: option.label,
});

export type DefaultValuesOverrideBehavior = 'onInit' | 'onChange';

function isActiveFilterEmpty(activeFilters: ActiveFiltersData) {
  const multiValueFilters = _.omit(activeFilters, ['searchTerm', 'group']);

  return Object.values(multiValueFilters).every(filter => !filter.values?.length);
}

export function useDefaultFilterValues({
  filterOptions,
  activeFilters,
  namespace,
}: {
  overrideBehavior?: DefaultValuesOverrideBehavior;
  filterOptions: Filter[];
  activeFilters: ActiveFiltersData;
  namespace?: string;
}) {
  const history = useHistory();
  const { queryParams } = useQueryParams();

  useEffect(() => {
    const defaultFilterOptions = filterOptions.filter(option =>
      Boolean(option.defaultValue ?? option.defaultValues?.length)
    );

    if (
      defaultFilterOptions?.length &&
      !queryParams.trigger &&
      !queryParams.pane &&
      isActiveFilterEmpty(activeFilters)
    ) {
      resetToDefaultFilters(defaultFilterOptions, namespace);
    }
  }, [filterOptions, activeFilters, namespace]);

  function resetToDefaultFilters(defaultFilterOptions: Filter[], namespace?: string) {
    defaultFilterOptions.forEach(initFilterOption);
    const updatedActiveFilter = defaultFilterOptions.reduce(
      (result, filterOption) => ({
        [filterOption.key]: { values: generateFilterDefaultValue(filterOption) },
        ...result,
      }),
      activeFilters
    );

    history.replace(
      makeUrl(history.location.pathname, {
        [namespace]: updatedActiveFilter,
        group: queryParams?.group ?? null,
      })
    );
  }
}

function initFilterOption(filterOption: Filter) {
  switch (filterOption.type) {
    case 'dateRange':
      if (filterOption.defaultValue) {
        // @ts-expect-error
        filterOption.preset = _.isArray(filterOption.defaultValue)
          ? DATE_CONSTANTS.CUSTOM
          : filterOption.defaultValue;
      }
      break;
    // no default
  }

  return filterOption;
}

function generateFilterDefaultValue(filterOption: Filter) {
  switch (filterOption.type) {
    case 'dateRange':
      return generateDateFilterDefaultValue(filterOption);
    case 'checkbox':
      return filterOption.defaultValue ? [filterOption.defaultValue] : filterOption.defaultValues;
    default:
      throw new Error(`Cannot generate default value for filter type ${filterOption.type}`);
  }

  function generateDateFilterDefaultValue(filterOption: Filter) {
    if (filterOption.defaultValues?.length) {
      if (filterOption.defaultValues.length !== 2) {
        console.error(
          `Datetime filter's default values must contain 2 items, but received: ${filterOption.defaultValues.length}`
        );
        return;
      }

      return filterOption.defaultValues;
    }

    const preset = parseInt(filterOption.defaultValue);

    if (!isNaN(preset)) {
      const end = new Date();
      const start = new Date();
      start.setDate(end.getDate() - preset);

      return [start.toISOString(), end.toISOString()];
    }
  }
}
