import { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { SelectControlV2 } from '@src-v2/components/forms/form-controls';
import { Field, Label } from '@src-v2/components/forms/modal-form-layout';
import { useProviderModalSettings } from '@src-v2/containers/modals/issues/providers-issue-modals';
import { useInject, useSuspense } from '@src-v2/hooks';
import { SearchParams } from '@src-v2/services';
import { Project } from '@src-v2/types/projects/project';

export const IssueDestinationControl = styled(({ provider, riskData, ...props }) => {
  const { ticketingIssues } = useInject();
  const { projectEntityPlaceholder, noIssueType } = useProviderModalSettings(provider);
  const { watch, setValue } = useFormContext();
  const project = watch('project');

  const preFilledDestination = useSuspense(ticketingIssues.getDefaultIssueDestination, {
    provider,
    riskLevel: riskData?.riskLevel,
    applicationKey: riskData?.applications?.[0]?.key,
  });

  useEffect(() => {
    if (preFilledDestination?.length !== 2) {
      return;
    }

    const [project, issueType] = convertPreFilled(preFilledDestination);
    setValue('project', project);
    setValue('issueType', issueType);
  }, [preFilledDestination]);

  return (
    <div {...props}>
      <Field>
        <Label required>Open in {projectEntityPlaceholder}</Label>
        <AsyncBoundary>
          <ProjectControl provider={provider} />
        </AsyncBoundary>
      </Field>

      {!noIssueType && (
        <Field>
          <Label required>Issue Type</Label>
          <AsyncBoundary>
            <IssueTypeControl provider={provider} project={project} />
          </AsyncBoundary>
        </Field>
      )}
    </div>
  );
})`
  display: flex;
  align-items: center;
  gap: 2rem;

  ${Field} {
    flex: 1;
  }

  ${LogoSpinner} {
    height: 9rem;
  }
`;

function convertPreFilled([project, issueType]) {
  return [
    { key: project.value, name: project.label },
    { id: issueType.value, name: issueType.label },
  ];
}

function ProjectControl({ provider }) {
  const { resetField } = useFormContext();
  const { withCreateIssuesPermission, projectEntityPlaceholder } =
    useProviderModalSettings(provider);

  const { ticketingIssues, asyncCache } = useInject();
  const searchProjects = useCallback(async ({ searchTerm }: SearchParams) => {
    const projects: Project[] = await asyncCache.suspend(ticketingIssues.getMonitoredProjects, {
      provider,
      withCreateIssuesPermission,
    });

    return !searchTerm?.length
      ? projects
      : projects.filter(project => project.name.toLowerCase().includes(searchTerm.toLowerCase()));
  }, []);

  const handleChange = useCallback(() => resetField('issueType'), [resetField]);

  return (
    <SelectControlV2
      name="project"
      rules={{ required: true }}
      placeholder={`Select ${projectEntityPlaceholder}...`}
      formatOptionLabel={project => project.name}
      searchMethod={searchProjects}
      onChange={handleChange}
    />
  );
}

function IssueTypeControl({ provider, project }) {
  const { asyncCache, ticketingIssues } = useInject();

  const searchIssueType = useCallback(async () => {
    const issueTypes = await asyncCache.suspend(ticketingIssues.getMonitoredProjectIssueTypes, {
      provider,
      projectKey: project?.key,
    });

    return issueTypes?.filter(issue => !issue.isSubTask);
  }, [provider, project]);

  return (
    <SelectControlV2
      name="issueType"
      searchable={false}
      rules={{ required: true }}
      placeholder="e.g. Story"
      searchMethod={searchIssueType}
      formatOptionLabel={option => option.name}
    />
  );
}
