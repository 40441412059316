import { isSameDay, isSameYear } from 'date-fns';
import { memo } from 'react';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { DateTime, TimeTooltip } from '@src-v2/components/time';
import RiskIcon from '@src/components/RiskIcon';
import { Ellipsis, FontBody } from '@src/style/common';

const Container = styled.div`
  position: relative;
  padding-top: ${props => (props.first ? '8rem' : '3rem')};
  padding-bottom: 3rem;

  &:after {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 16rem;
    display: block;
    width: 0;
    content: '';
    border-left: 0.25rem solid var(--color-blue-gray-35);
    z-index: 1;
  }
`;

const TimelineMarker = styled.div`
  &:after {
    position: absolute;
    bottom: -3.75rem;
    left: 14.25rem;
    display: block;
    width: 3.75rem;
    height: 3.75rem;
    content: '';
    border-radius: 100vmax;
    border: 0.25rem solid var(--color-blue-gray-35);
    z-index: 2;
  }

  &:first-child:after {
    bottom: 0;
    top: -3.75rem;
  }
`;

const StyledTime = styled(DateTime)`
  display: block;
`;

const StyledTimeDay = styled(StyledTime)`
  font-size: 24px;
  line-height: 32px;
  font-weight: 600;
`;

const StyledTimeMonth = styled(StyledTime)`
  ${FontBody};
  color: inherit;
`;

const StyledRiskIcon = styled(RiskIcon)`
  z-index: 2;
`;

const StyledTimeYear = styled(StyledTime)`
  ${FontBody};
  background-color: var(--color-blue-gray-20);
  display: inline-block;
  padding: 6px 18px;
  border-radius: 18px;
  margin: 9rem 8rem;
  z-index: 2;
`;

const TimelineEventRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  z-index: 0;
`;

const Timestamp = styled.div`
  display: inline-block;
  width: 40px;
  visibility: ${props => (props.hide ? 'hidden' : 'visible')};
  text-align: center;
  color: var(--default-text-color);
`;

const Body = styled.div`
  flex-grow: 1;
  font-weight: 500;
  ${Ellipsis};
`;

const TimelineEventTitle = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 1.5rem;
  flex-wrap: wrap;
  font-weight: 300;
  margin-bottom: 3rem;

  > *:hover {
    color: var(--color-blue-gray-55);
  }
`;

const BodyContainer = styled.div`
  position: relative;
  display: flex;
  max-width: 80%;
  flex-grow: 1;
  flex-direction: row;
  gap: 3rem;
  border-radius: 2rem;
  padding-right: 6rem;
  padding-left: 2.25rem;

  &:hover {
    background-color: var(--color-blue-gray-15);
  }

  ${Body},
  ${StyledRiskIcon} {
    margin-top: 3rem;
  }
`;

const TimelineYear = props => (
  <TimelineEventRow>
    <StyledTimeYear format="y" {...props} />
  </TimelineEventRow>
);

export const TimelineEvent = memo(
  ({
    currentTimelineEvent,
    previousTimelineEvent,
    index,
    isFirst,
    isLast,
    titleContent,
    body,
    riskLevel,
    exploreUrl,
    testMarker,
  }) => {
    const { timestamp: currentTimestamp } = currentTimelineEvent;
    const { timestamp: previousTimestamp } = previousTimelineEvent ?? {};

    const currentDate = new Date(currentTimestamp);
    const previousDate = previousTimestamp ? new Date(previousTimestamp) : null;

    const timelineYearTimestamp =
      (previousDate && !isSameYear(currentDate, previousDate)) || isFirst ? currentTimestamp : null;

    return (
      <Container first={isFirst} data-test-marker={testMarker} data-test-id={`timeline-${index}`}>
        {isFirst && <TimelineMarker />}
        {timelineYearTimestamp && <TimelineYear date={timelineYearTimestamp} />}
        <TimelineEventRow>
          <Timestamp hide={index > 0 && isSameDay(currentDate, previousDate)}>
            <TimeTooltip date={currentTimestamp}>
              <StyledTimeDay date={currentTimestamp} format="d" />
            </TimeTooltip>
            <StyledTimeMonth date={currentTimestamp} format="MMM" />
          </Timestamp>
          <BodyContainer>
            <StyledRiskIcon
              riskLevel={riskLevel ?? currentTimelineEvent.materialChangesRiskLevel}
            />
            <Body>
              <TimelineEventTitle>{titleContent}</TimelineEventTitle>
              {body}
            </Body>
            {exploreUrl && (
              <Button to={exploreUrl} variant="secondary" endIcon="Arrow">
                Explore
              </Button>
            )}
          </BodyContainer>
        </TimelineEventRow>
        {isLast && <TimelineYear date={currentTimestamp} /> && <TimelineMarker />}
      </Container>
    );
  }
);
