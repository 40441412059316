import { formatDate } from 'date-fns';
import _ from 'lodash';
import { useRef, useState } from 'react';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { DeleteButton } from '@src-v2/components/buttons';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Checkbox } from '@src-v2/components/forms';
import { CheckboxControl } from '@src-v2/components/forms/form-controls';
import { BaseIcon, SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { TabsRouter } from '@src-v2/components/tabs/tabs-router';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Paragraph } from '@src-v2/components/typography';
import { dateFormats } from '@src-v2/data/datetime';
import { getProviderDisplayName } from '@src-v2/data/providers';
import { useInject, useQueryParams, useSuspense } from '@src-v2/hooks';
import { classNames } from '@src-v2/utils/style-utils';
import ServerTableInfiniteScroll from '@src/blocks/ServerTableInfiniteScroll';
import { InfiniteScrollerContainer } from '@src/components/TableInfiniteScroll';
import { FontBody, FontBodyHeader } from '@src/style/common';
import TableSearch from '../blocks/TableSearch';
import { CircledIcon } from '../style/common';
import { Button } from './Button';
import { Table } from './Table';
import { VerticalStack } from './VerticalStack';

export const ForeignEntityBackConnections = props => (
  <AsyncBoundary>
    <ForeignEntityBackConnectionsContent {...props} />
  </AsyncBoundary>
);

const ForeignEntityBackConnectionsContent = ({
  linkTarget,
  linkTargetDescription,
  profile,
  ...props
}) => {
  const { foreignEntities } = useInject();

  const linkableProviders = useSuspense(foreignEntities.getLinkableProviders, { linkTarget });

  if (!linkableProviders?.length) {
    return <Paragraph>No connections are available for {linkTargetDescription}.</Paragraph>;
  }

  return (
    <StyledTabs
      variant="secondary"
      className={classNames(props.className, FontBodyHeader)}
      routes={linkableProviders.map(provider => ({
        title: getProviderDisplayName(provider),
        path: provider,
        render: () => (
          <AsyncBoundary>
            <ProviderBackConnections
              providerType={provider}
              linkTarget={linkTarget}
              linkTargetDescription={linkTargetDescription}
              profile={profile}
            />
          </AsyncBoundary>
        ),
      }))}
    />
  );
};

function ProviderBackConnections({ providerType, linkTarget, linkTargetDescription, profile }) {
  const {
    queryParams: { searchTerm },
  } = useQueryParams();

  const { foreignEntities } = useInject();

  const foreignEntitiesList = useSuspense(foreignEntities.getForeignEntitiesByLinkTarget, {
    linkTarget,
    providerType,
  });

  const filteredEntities = entitiesFilteredBySearchTerm(foreignEntitiesList, searchTerm);

  const [showLinkForeignEntityDialog, setShowLinkForeignEntityDialog] = useState();
  const [entityForDeletionConfirmation, setEntityForDeletionConfirmation] = useState();

  const handleAddLinkClick = () => {
    setShowLinkForeignEntityDialog(true);
  };

  const handleAddLinkClose = () => {
    setShowLinkForeignEntityDialog(false);
  };

  const handleDeleteLinkClose = () => {
    setEntityForDeletionConfirmation(null);
  };

  const customActionsSection = (
    <StyledAddButton onClick={handleAddLinkClick}>
      <StyledAddIcon>
        <Tooltip content="Match provider entities">
          <SvgIcon name="Plus" />
        </Tooltip>
      </StyledAddIcon>
    </StyledAddButton>
  );

  return (
    <>
      <VerticalStack>
        <TableSearch
          filterOptions={{}}
          placeholder=""
          resultName="matched entity"
          resultNamePlural="matched entities"
          itemsCount={filteredEntities.length}
          customActionsSection={customActionsSection}
          initialSearchTerm={searchTerm}
        />
        <EntitiesTable
          entities={filteredEntities}
          displayedColumnIds={{
            name: true,
            ...(providerType === 'FindingsApi' && { findingsApiProvider: true }),
            type: true,
            ...(providerType === 'FindingsApi' && { scanType: true }),
            ...(providerType === 'FindingsApi' && { uploadTime: true }),
            actions: true,
          }}
          rowActionsForEntity={entity => [
            <DeleteButton onClick={() => setEntityForDeletionConfirmation(entity)} />,
          ]}
          providerType={providerType}
        />
      </VerticalStack>
      {showLinkForeignEntityDialog && (
        <AddLinkModal
          linkTarget={linkTarget}
          linkTargetDescription={linkTargetDescription}
          profile={profile}
          onClose={handleAddLinkClose}
          providerType={providerType}
        />
      )}
      {entityForDeletionConfirmation && (
        <DeleteLinkModal
          linkTarget={linkTarget}
          entity={entityForDeletionConfirmation}
          onClose={handleDeleteLinkClose}
        />
      )}
    </>
  );
}

function DeleteLinkModal({ linkTarget, entity, onClose }) {
  const { foreignEntities } = useInject();

  return (
    <ConfirmationModal
      title="Unmatch Entity?"
      submitText="Unmatch"
      onClose={onClose}
      onSubmit={async () => {
        await foreignEntities.removeLink({ entityKeys: [entity.key], linkTarget });
        onClose?.();
      }}>
      Are you sure you want to unmatch {entity.name}?
    </ConfirmationModal>
  );
}

function AddLinkModal({ linkTarget, linkTargetDescription, onClose, providerType, profile }) {
  const { foreignEntities } = useInject();

  const handleSubmit = async data => {
    const selectedKeys = Object.keys(data)
      .filter(key => Boolean(data[key]))
      .map(key => _.unescape(key));
    await foreignEntities.addLinkToMultipleForeignEntities({
      entityKeys: selectedKeys,
      linkTarget,
    });
    onClose();
  };

  return (
    <LinkEntitiesStyledConfirmationModal
      title="Match provider projects / entities"
      submitText="Match"
      onClose={onClose}
      onSubmit={handleSubmit}>
      <SubtitleContainer>
        Select <VendorIcon name={providerType} /> {getProviderDisplayName(providerType)} entities to
        match to <VendorIcon name={profile.provider} /> {linkTargetDescription}
      </SubtitleContainer>
      <AsyncBoundary>
        <LinkableEntitiesList linkTarget={linkTarget} providerType={providerType} />
      </AsyncBoundary>
    </LinkEntitiesStyledConfirmationModal>
  );
}

function LinkableEntitiesList({ linkTarget, providerType }) {
  const wrapperRef = useRef();

  return (
    <TableWrapper ref={wrapperRef}>
      <ServerTableInfiniteScroll
        tableScope={`foreign-entities/${providerType}`}
        searchPlaceholder="Search entity name"
        resultName="possible entity"
        resultNamePlural="possible entities"
        allowSearchAndFilter
        searchParams={{ linkTarget }}
        scrollParent={wrapperRef}
        headers={_.compact([
          { name: 'Selection', weight: 0.5 },
          { name: providerType === 'FindingsApi' ? 'Project name' : 'Name', weight: 2 },
          providerType === 'FindingsApi' && {
            name: 'Provider name',
            weight: 1,
          },
          { name: 'Type', weight: 2 },
          providerType === 'FindingsApi' && {
            name: 'Category',
            weight: 1,
          },
          providerType === 'FindingsApi' && {
            name: 'Uploaded on',
            weight: 2,
          },
        ])}
        rowProvider={foreignEntity => ({
          key: foreignEntity.key,
          cells: _.compact([
            {
              content: <CheckboxControl name={_.escape(foreignEntity.key)} />,
            },
            {
              content: foreignEntity.foreignEntityMatchingExtraData[1]?.branchName
                ? `${foreignEntity.displayName} (${foreignEntity?.foreignEntityMatchingExtraData[1]?.branchName})`
                : foreignEntity.displayName,
            },
            providerType === 'FindingsApi' && {
              content: foreignEntity.findingsApiProvider,
            },
            { content: foreignEntity.typeDisplayName },
            providerType === 'FindingsApi' && {
              content: foreignEntity.scanType,
            },
            providerType === 'FindingsApi' && {
              content: foreignEntity.uploadTime
                ? formatDate(new Date(foreignEntity.uploadTime), dateFormats.longDatetime)
                : null,
            },
          ]),
        })}
      />
    </TableWrapper>
  );
}

function EntitiesTable({
  entities,
  displayedColumnIds,
  selectedKeys,
  setSelectedKeys,
  rowActionsForEntity,
  emptyMessage,
  providerType,
}) {
  const allColumns = _.compact([
    {
      id: 'selection',
      header: { name: '', weight: 0.5 },
      cellGenerator: entity => ({
        content: (
          <Checkbox
            checked={selectedKeys.indexOf(entity.key) >= 0}
            onChange={event =>
              setSelectedKeys(
                event.target.checked
                  ? selectedKeys.concat(entity.key)
                  : selectedKeys.filter(k => k !== entity.key)
              )
            }
          />
        ),
      }),
    },

    {
      id: 'name',
      header: { name: providerType === 'FindingsApi' ? 'Project name' : 'Name', weight: 2 },
      cellGenerator: entity => ({
        text: entity?.foreignEntityMatchingExtraData[1]?.branchName
          ? `${entity.displayName} (${entity?.foreignEntityMatchingExtraData[1]?.branchName})`
          : entity.displayName,
      }),
    },
    providerType === 'FindingsApi' && {
      id: 'findingsApiProvider',
      header: { name: 'Provider name ', weight: 1 },
      cellGenerator: entity => ({
        text: entity.findingsApiProvider,
      }),
    },
    {
      id: 'type',
      header: { name: 'Type', weight: 1 },
      cellGenerator: entity => ({
        text: entity.typeDisplayName,
      }),
    },
    providerType === 'FindingsApi' && {
      id: 'scanType',
      header: { name: 'Category', weight: 1 },
      cellGenerator: entity => ({
        text: entity.scanType,
      }),
    },
    providerType === 'FindingsApi' && {
      id: 'uploadTime',
      header: { name: 'Uploaded on', weight: 1 },
      cellGenerator: entity => ({
        text: entity.uploadTime
          ? formatDate(new Date(entity.uploadTime), dateFormats.longDatetime)
          : null,
      }),
    },
    {
      id: 'actions',
      header: { name: '', weight: 0.5 },
      cellGenerator: entity => ({
        menu: rowActionsForEntity(entity),
      }),
    },
  ]);

  const displayedColumns = allColumns.filter(col => col.id in displayedColumnIds);

  const rows = entities.map(entity => ({
    key: entity.key,
    cells: displayedColumns.map(col => col.cellGenerator(entity)),
  }));

  const headers = displayedColumns.map(col => col.header);

  return (
    <Table
      noTopPadding
      rows={rows}
      headers={headers}
      tableIsEmpty={_.isEmpty(rows)}
      emptyMessage={emptyMessage}
      headerBackground={false}
    />
  );
}

function entitiesFilteredBySearchTerm(entities, searchTerm) {
  return entities.filter(entity =>
    entity.displayName.toLowerCase().includes((searchTerm || '').toLowerCase())
  );
}

const TableWrapper = styled.div`
  ${InfiniteScrollerContainer} {
    max-height: 60vh;
    overflow: auto;
  }
`;

const StyledAddButton = styled(Button)`
  padding: 0;
  align-self: center;
  border: 0;
`;

const StyledAddIcon = styled.div`
  ${CircledIcon};
  background-color: ${props => (props.open ? 'transparent' : 'var(--color-white)')};

  ${BaseIcon} {
    height: 6rem;
    width: 6rem;
  }
`;

const StyledTabs = styled(TabsRouter)`
  ${FontBodyHeader};

  & + & {
    ${FontBody};
  }

  margin: 4rem 0;
`;

const LinkEntitiesStyledConfirmationModal = styled(ConfirmationModal)`
  width: 300rem !important;
`;

const SubtitleContainer = styled(Paragraph)`
  display: flex;
  padding: 0 2rem 2rem 0;
  gap: 1rem;
`;
