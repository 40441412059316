import { useCallback } from 'react';
import { RadioGroupControl, TextareaControl } from '@src-v2/components/forms/form-controls';
import { SvgIcon } from '@src-v2/components/icons';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { OverrideModal } from '@src-v2/containers/action-modals/override-risk/override-modal';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { pluralFormat } from '@src-v2/utils/number-utils';

export function IgnoreRisksModal({ data, onClose, ...props }) {
  const { risks, session, rbac } = useInject();
  const risksCount = data.length;

  const canEdit = rbac.canEdit(resourceTypes.RiskStatus);

  const handleIgnore = useCallback(
    async ({ ignoreReasonText, ignoreType }) => {
      await risks.bulkIgnoreRisks({ riskTriggers: data, ignoreReasonText, ignoreType });

      const riskOverrideData = {
        reason: ignoreReasonText,
        ignoreType,
        riskLevel: 'Ignored',
        riskStatus: 'Ignored',
        user: session.username,
        timestamp: new Date(),
      };

      data.forEach(riskItem => risks.modifyRiskLevel(riskItem, 'Ignored', riskOverrideData));
      onClose?.();
    },
    [data, onClose]
  );
  return (
    <OverrideModal
      {...props}
      onClose={onClose}
      disabledSubmitButton={!canEdit}
      onSubmit={handleIgnore}
      defaultValues={{ ignoreType: ignoreOptions[0].value }}
      title={
        <>
          <SvgIcon name="Invisible" />
          <Heading>Ignore {pluralFormat(risksCount, 'Risk')}</Heading>
        </>
      }>
      <Paragraph>
        {pluralFormat(risksCount, 'This is not a risk', 'These are not risks')} and shouldn't be
        considered in the risk score. Please provide feedback
      </Paragraph>
      <RadioGroupControl name="ignoreType" options={ignoreOptions} />
      <TextareaControl
        name="ignoreReasonText"
        placeholder="Add an ignore reason..."
        charLimit={500}
      />
    </OverrideModal>
  );
}

const ignoreOptions = [
  { value: 'falsePositive', label: "It's a false positive" },
  { value: 'notRelevant', label: "It's not relevant" },
];
