import _ from 'lodash';
import styled from 'styled-components';
import { ManagedCheckovMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/managed-checkov-material-change';
import Bold from '@src/components/Bold';
import Dependency, { DependencyMaterialChange } from '@src/components/Dependency';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import { EntityInsightsMaterialChange } from '@src/components/MaterialChange/EntityInsightsMaterialChange';
import { GqlObjectMaterialChange } from '@src/components/MaterialChange/GqlObjectMaterialChange';
import { GqlOperationMaterialChange } from '@src/components/MaterialChange/GqlOperationMaterialChange';
import ProtobufMessageMaterialChange from '@src/components/MaterialChange/ProtobufMessageMaterialChange';
import ProtobufServiceMaterialChange from '@src/components/MaterialChange/ProtobufServiceMaterialChange';
import SensitiveDataPresenceMaterialChange from '@src/components/MaterialChange/SensitiveDataPresenceMaterialChange';
import { Number } from '@src/components/Number';
import { VerticalStack } from '@src/components/VerticalStack';
import AbnormalBehaviorMaterialChange from './AbnormalBehaviorMaterialChange';
import ApiAuthorizationRoleMaterialChange from './ApiAuthorizationRolMaterialChange';
import ApiBehindApiGatewayMaterialChange from './ApiBehindApiGatewayMaterialChange';
import ApiCheckmarxVulnerabilityMaterialChange from './ApiCheckmarxVulnerabilityMaterialChange';
import ApiClassificationMaterialChange from './ApiClassificationMaterialChange';
import ApiMaterialChange from './ApiMaterialChange';
import ApiMethodParameterMaterialChange from './ApiMethodParameterMaterialChange';
import ApiSecurityPolicyMaterialChange from './ApiSecurityPolicyMaterialChange';
import DataModelAccessMaterialChange from './DataModelAccessMaterialChange';
import DeveloperFirstCommitToRepositoryMaterialChange from './DeveloperFirstCommitToRepositoryMaterialChange';
import DockerfileMaterialChange from './DockerfileMaterialChange';
import InputValidationMaterialChange from './InputValidationMaterialChange';
import { CommitCodeReference, thenSubTypeToVerb } from './MaterialChangeUtils';
import NewDeveloperMaterialChange from './NewDeveloperMaterialChange';
import RbacRoleMaterialChange from './RbacRoleMaterialChange';
import SecurityControlsMaterialChange from './SecurityControlsMaterialChange';
import SensitiveDataExitPointMaterialChange from './SensitiveDataExitPointMaterialChange';
import SensitiveDataMaterialChange from './SensitiveDataMaterialChange';
import ServerlessFunctionMaterialChange from './ServerlessFunctionMaterialChange';
import StorageBucketAccessMaterialChange from './StorageBucketAccessMaterialChange';
import { StyledHighlightedCode, StyledHorizontalStack, StyledWrappedTitledList } from './Styles';
import TerraformMisconfigurationsMaterialChange from './TerraformMisconfigurationsMaterialChange';
import TerraformResourceMaterialChange from './TerraformResourceMaterialChange';

const fileName = filePath => filePath.replace(/^.*[\\/]/, '');

const MaterialChange = ({
  governanceRule,
  thenSubType,
  materialChange,
  repository,
  commitSha,
  whenIndex = 0,
  developerProfileByIdentityKey,
}) => {
  const { type: whenType, value: whenValue } = governanceRule.when[whenIndex];
  const { fieldNames } = materialChange ?? {};
  const { name, className, relativeFilePath, lineNumber, methodName, apiName, methodSignature } =
    materialChange?.codeReference ?? {};
  const displayName = name || className || apiName;

  if (materialChange.partialMaterialChanges) {
    return (
      <CompoundMaterialChange
        materialChange={materialChange}
        governanceRule={governanceRule}
        thenSubType={thenSubType}
        repository={repository}
        commitSha={commitSha}
        developerProfileByIdentityKey={developerProfileByIdentityKey}
      />
    );
  }

  switch (whenType) {
    case 'ManagedCheckov':
      return (
        <ManagedCheckovMaterialChange
          isAdded={materialChange.isAdded}
          commitSha={commitSha}
          repository={repository}
          lineNumber={materialChange.entity.lineNumber}
          filePath={materialChange.entity.filePath}
          entityDisplayName={materialChange.entity.displayName}
          technologyDisplayName={
            materialChange.entity.cicdPipelineDeclarationReferences[0].cicdTechnologyDisplayName
          }
        />
      );
    case 'CommitBehavior':
      return <AbnormalBehaviorMaterialChange materialChange={materialChange} />;
    case 'InputValidation':
      return (
        <InputValidationMaterialChange
          materialChange={materialChange}
          thenSubType={thenSubType}
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}
          whenValue={whenValue}
        />
      );

    case 'Secrets':
      const { secret } = materialChange;
      return (
        <StyledWrappedTitledList
          title={
            <StyledHorizontalStack>
              <span>Secrets were {thenSubType === 'Removed' ? 'removed' : 'exposed'} at</span>
              <Bold>
                <CommitCodeReference
                  repository={repository}
                  commitSha={commitSha}
                  relativeFilePath={secret.relativeFilePath}
                  lineNumber={lineNumber}>
                  {fileName(secret.relativeFilePath)}
                </CommitCodeReference>
              </Bold>
              <span>of type</span>
              <Bold>{secret.fileClassification}</Bold>
            </StyledHorizontalStack>
          }
          list={secret.censoredValues}
        />
      );

    case 'Licenses':
      const { licenseName, dependencies } = materialChange;
      return (
        <StyledWrappedTitledList
          title={
            <StyledHorizontalStack>
              <span>Dependant on packages with license</span>
              <Bold>{licenseName}</Bold>
            </StyledHorizontalStack>
          }
          list={dependencies.map(dependency => (
            <Dependency showVersions key={dependency.name} dependency={dependency} />
          ))}
        />
      );

    case 'SensitiveData':
      return (
        <SensitiveDataMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          whenValue={whenValue}
          thenSubType={thenSubType}
          name={name}
          methodName={methodName}
          fieldNames={fieldNames}
          lineNumber={lineNumber}
        />
      );

    case 'Authorization':
      return (
        <VerticalStack>
          <StyledHorizontalStack>
            <span>Authorization is missing for API methods of </span>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}
              lineNumber={lineNumber}>
              {displayName}
            </CommitCodeReference>
            {whenValue !== 'any' && <span>under {whenValue}</span>}
          </StyledHorizontalStack>
          <StyledHighlightedCode key={methodSignature} code={methodSignature} language="java" />
        </VerticalStack>
      );

    case 'Authentication': // Relevant only to Node and demo
      return (
        <StyledWrappedTitledList
          title={
            <HorizontalStack>
              <Bold>Authentication changes</Bold>
              <span> to API </span>
              <Bold>{apiName}</Bold>
              <span>defined at</span>
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={relativeFilePath}
                lineNumber={lineNumber}>
                {displayName || relativeFilePath}
              </CommitCodeReference>
            </HorizontalStack>
          }
          list={materialChange.descriptions}
        />
      );

    case 'ExposedToInternet': // This is a demo only option
      return (
        <StyledHorizontalStack>
          <span>Service</span>
          <Bold>{materialChange.serviceReference.name}</Bold>
          <span>is</span>
          <Link
            openInNewTab
            underline
            url="https://bitbucket.apiiro.com:8443/projects/LIM/repos/terraform-lab/commits/9b71c152c195fa0229606c62d7ba67ce8b69a05d">
            exposed to the internet
          </Link>
        </StyledHorizontalStack>
      );

    case 'ExternalDependency':
      const { dependency, previousVersion, newVersion } = materialChange;
      switch (thenSubType) {
        case 'Downgraded':
        case 'Upgraded':
          return (
            <DependencyMaterialChange dependency={dependency} thenSubType={thenSubType}>
              {' '}
              from
              <Bold>{previousVersion}</Bold>
              to
              <Bold>{newVersion}</Bold>
            </DependencyMaterialChange>
          );

        default:
          return (
            <DependencyMaterialChange dependency={dependency} thenSubType={thenSubType}>
              {' '}
              at
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={dependency?.codeReference?.relativeFilePath}>
                {name ?? dependency.codeReference.relativeFilePath}
              </CommitCodeReference>
            </DependencyMaterialChange>
          );
      }

    case 'PipelineDependencyFindings':
      return (
        <StyledHorizontalStack>
          <span>Vulnerability</span>
          <span>
            <Number one="CVE" other="CVEs" value={materialChange.cveCount} />
          </span>
          <span>{materialChange.cveCount > 1 ? 'were' : 'was'}</span>
          <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
          <Dependency
            showVersions
            repository={repository}
            commitSha={commitSha}
            dependency={materialChange.dependency}
          />
        </StyledHorizontalStack>
      );
    case 'ScaFindings':
      return (
        <StyledHorizontalStack>
          <span>Vulnerability</span>
          <span>
            <Number one="CVE" other="CVEs" value={materialChange.cveCount} />
          </span>
          <span>{materialChange.cveCount > 1 ? 'were' : 'was'}</span>
          <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
          <Dependency showVersions dependency={materialChange.dependency} />
        </StyledHorizontalStack>
      );

    case 'DataModel':
      return (
        <StyledWrappedTitledList
          title={
            <HorizontalStack>
              <span>Data model </span>
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={relativeFilePath}
                lineNumber={lineNumber}>
                {displayName}
              </CommitCodeReference>
              <span>was</span>
              <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>
            </HorizontalStack>
          }
          list={materialChange.descriptions}
        />
      );

    case 'Infrastructure':
      return (
        <TerraformResourceMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
        />
      );

    case 'Dockerfile':
      return (
        <DockerfileMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
        />
      );

    case 'Api':
      return (
        <ApiMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );

    case 'SecurityControls':
      return (
        <SecurityControlsMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );

    case 'SensitiveFiles':
      const { filePath, changeType } = materialChange;
      return (
        <StyledHorizontalStack>
          <span>File</span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={filePath}
            lineNumber={lineNumber}>
            {fileName(filePath)}
          </CommitCodeReference>
          <Bold>{thenSubTypeToVerb(changeType)}</Bold>
        </StyledHorizontalStack>
      );

    case 'PipelineConfigurationFiles':
      return (
        <StyledHorizontalStack>
          <span>Pipeline configuration file</span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={materialChange.filePath}
            lineNumber={lineNumber}>
            {fileName(materialChange.filePath)}
          </CommitCodeReference>
          <span>of provider</span>
          <Bold>{materialChange.iacFrameworkDescription}</Bold>
          <span>was</span>
          <Bold>{thenSubTypeToVerb(materialChange.changeType)}</Bold>
        </StyledHorizontalStack>
      );

    case 'ApiClassification': {
      return (
        <ApiClassificationMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'ApiAuthorizationRole': {
      return (
        <ApiAuthorizationRoleMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'ApiSecurityPolicy': {
      return (
        <ApiSecurityPolicyMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'BehindApiGateway': {
      return (
        <ApiBehindApiGatewayMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'LegacySastFindings': {
      return (
        <ApiCheckmarxVulnerabilityMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
          apiName={displayName}
        />
      );
    }

    case 'NewDeveloper': {
      return (
        <NewDeveloperMaterialChange
          materialChange={materialChange}
          developerProfileByIdentityKey={developerProfileByIdentityKey}
        />
      );
    }

    case 'DeveloperFirstCommitToRepository': {
      return (
        <DeveloperFirstCommitToRepositoryMaterialChange
          materialChange={materialChange}
          developerProfileByIdentityKey={developerProfileByIdentityKey}
        />
      );
    }

    case 'RbacRole': {
      return (
        <RbacRoleMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'DataModelAccess': {
      return (
        <DataModelAccessMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'MandatoryTechnology':
      const { mandatoryFramework, mandatoryTechnologyDisplayName } = materialChange;
      return (
        <StyledHorizontalStack>
          <span>
            Usage of unauthorized technology <Bold>{mandatoryFramework}</Bold> of type{' '}
            <Bold>{mandatoryTechnologyDisplayName}</Bold> was{' '}
            <Bold>{thenSubTypeToVerb(thenSubType)}</Bold> {thenSubType === 'Added' ? 'at' : 'from'}
          </span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}
            lineNumber={lineNumber}>
            {fileName(relativeFilePath)}
          </CommitCodeReference>
        </StyledHorizontalStack>
      );

    case 'Technology':
      const { framework, technologyDisplayName } = materialChange;
      return (
        <StyledHorizontalStack>
          <span>
            Usage of the framework <Bold>{framework}</Bold> of type{' '}
            <Bold>{technologyDisplayName}</Bold> was <Bold>{thenSubTypeToVerb(thenSubType)}</Bold>{' '}
            {thenSubType === 'Added' ? 'at' : 'from'}
          </span>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}
            lineNumber={lineNumber}>
            {fileName(relativeFilePath)}
          </CommitCodeReference>
        </StyledHorizontalStack>
      );

    case 'UserFacing':
      return <span>Is user facing</span>;

    case 'ApiMethodParameters':
      return (
        <ApiMethodParameterMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          relativeFilePath={relativeFilePath}
        />
      );

    case 'AccessingStorageBucket':
      return (
        <StorageBucketAccessMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          relativeFilePath={relativeFilePath}
          thenSubType={thenSubType}
        />
      );

    case 'SensitiveDataExitPoint':
      return (
        <SensitiveDataExitPointMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          relativeFilePath={relativeFilePath}
          name={name}
        />
      );

    case 'TerraformMisconfiguration':
      return (
        <TerraformMisconfigurationsMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
        />
      );

    case 'ServerlessFunction':
      const { serverlessFunction } = materialChange;
      return (
        <ServerlessFunctionMaterialChange
          serverlessFunction={serverlessFunction}
          repository={repository}
          commitSha={commitSha}
        />
      );

    case 'ProtobufMessage':
      const { protobufMessage, descriptions, contentSummary, codeReference } = materialChange;
      return (
        <ProtobufMessageMaterialChange
          protobufMessage={protobufMessage}
          repository={repository}
          commitSha={commitSha}
          descriptions={descriptions}
          thenSubType={thenSubType}
          contentSummary={contentSummary}
          codeReference={codeReference}
        />
      );

    case 'ProtobufService':
      return (
        <ProtobufServiceMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
          thenSubType={thenSubType}
        />
      );

    case 'SensitiveDataPresence':
      return (
        <SensitiveDataPresenceMaterialChange
          codeDataTypeName={materialChange.associatedSensitiveDataTypeDescription}
        />
      );

    case 'GqlObject':
      return (
        <GqlObjectMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
          thenSubType={thenSubType}
        />
      );

    case 'GqlOperation':
      return (
        <GqlOperationMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
          thenSubType={thenSubType}
        />
      );

    case 'EntityInsights':
      return (
        <EntityInsightsMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
          thenSubType={thenSubType}
        />
      );

    default:
      console.error(`Unsupported material change When type: ${whenType}`);
      return <div />;
  }
};

const StyledSpan = styled.span`
  padding-left: ${props => (props.level === '1' ? '6rem' : props.level === '2' ? '12rem' : '6px')};
`;

const CompoundMaterialChange = ({
  materialChange,
  governanceRule,
  thenSubType,
  repository,
  commitSha,
  developerProfileByIdentityKey,
}) => {
  const currentWhenIndexes = materialChange.partialMaterialChanges.flatMap(
    partialMaterialChange => partialMaterialChange.ruleWhenIndexes
  );
  const missingWhenIndexesCount = _.difference(
    _.range(governanceRule.when.length),
    currentWhenIndexes
  ).length;

  return (
    <>
      <StyledWrappedTitledList
        boxborder
        title={<span>{governanceRule.name}</span>}
        list={materialChange.partialMaterialChanges.map(partialMaterialChange => (
          <MaterialChange
            key={partialMaterialChange.key}
            governanceRule={governanceRule}
            thenSubType={thenSubType}
            materialChange={partialMaterialChange}
            repository={repository}
            commitSha={commitSha}
            renderActions={false}
            whenIndex={partialMaterialChange.ruleWhenIndexes[0]}
            developerProfileByIdentityKey={developerProfileByIdentityKey}
          />
        ))}
      />
      {missingWhenIndexesCount > 0 && (
        <VerticalStack>
          <StyledSpan level="1">
            Combined with <Number value={missingWhenIndexesCount} one="change" other="changes" />
          </StyledSpan>
          <StyledSpan level="2">Introduced in previous commits</StyledSpan>
        </VerticalStack>
      )}
    </>
  );
};

export default MaterialChange;
