import find from 'lodash/find';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import keys from 'lodash/keys';
import map from 'lodash/map';
import pick from 'lodash/pick';
import { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { CircleButton } from '@src-v2/components/button-v2';
import { DeleteButton } from '@src-v2/components/buttons';
import { ExpressionEditor } from '@src-v2/components/expression-editor';
import { SvgIcon } from '@src-v2/components/icons';
import { RepositoryInfoTooltip } from '@src-v2/components/repositories/repository-info-tooltip';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { StyledMenu } from '@src/components/ReactSelect/ReactSelectComponents';
import ReactSelectWrapper from '@src/components/ReactSelect/ReactSelectWrapper';
import AdditionalPropertiesInput from '@src/components/Rule/AdditionalPropertiesInput';
import ModuleInput from '@src/components/Rule/ModuleInput';
import MultiSelectInput from '@src/components/Rule/MultiSelectInput';
import { OptionText, StyledOption, StyledProperties } from '@src/components/Rule/RuleOption';
import StorageBucketInput from '@src/components/Rule/StorageBucketInput';
import { Tooltip } from '@src/components/Tooltip';
import { Input } from '@src/components/reactstrap-polyfill';
import rulesService, { AdditionalPropertiesType } from '@src/services/rulesService';
import { Ellipsis, FontBodySmall, HideScrollBar } from '@src/style/common';

const StyledTooltip = styled(Tooltip)`
  margin: 6px;
`;

const StyledInput = styled(Input)`
  ${Ellipsis};
  ${FontBodySmall};
  background-color: white !important;

  ::placeholder {
    color: var(--color-blue-gray-45);
  }

  flex: 1;
  height: 7rem;
  padding: 1.5rem 3rem;
  border: none;
  border-radius: 4.5rem;
  ${({ shortinput }) =>
    shortinput &&
    `
    width: 12rem;
    text-align: center;
  `}
`;

const StyledExpressionEditor = styled(ExpressionEditor)`
  ${FontBodySmall};
  ${HideScrollBar};

  background-color: white !important;
  flex: 1;
  height: 7rem;
  padding: 1.5rem 3rem;
  border: none;
  border-radius: 4.5rem;

  min-width: 24rem;
  max-width: 48rem;
`;

const StyledFirstLabel = styled.span`
  padding-left: 2rem;
`;

const SelectStyle = css`
  max-width: 100%;
`;

const StyledStorageBucketInput = styled(StorageBucketInput)`
  ${SelectStyle}
`;

const StyledModuleInput = styled(ModuleInput)`
  ${SelectStyle}
`;

const StyledAdditionalPropertiesInput = styled(AdditionalPropertiesInput)`
  ${SelectStyle}
`;

const StyledSelect = styled(ReactSelectWrapper)`
  ${SelectStyle}
`;

const RepositorySelect = styled(ReactSelectWrapper)`
  ${StyledMenu} {
    width: 100rem;
  }
`;

const Option = ({
  disabled,
  isSingleOption,
  selected,
  validationError,
  options,

  removeOption,
  addProperty,
  setProperty,
  removeProperty,
  setSelection,
}) => {
  const selectedType = options[selected.type];
  const selectedSubType = selected.subType
    ? find(selectedType.subTypes, ['key', selected.subType])
    : null;
  const selectedValue = find(selectedType.options, ['key', selected.values[0]]) ?? {
    key: selected.values[0],
    displayName: 'N/A',
  };
  const valueSettings = rulesService.getValuesSettings(selectedType, selected);
  const isMultiValue = selectedType.multi || selectedSubType?.multi;

  const optionsConjunction = selectedType.optionsConjunction ? (
    <OptionText>{selectedType.optionsConjunction}</OptionText>
  ) : null;

  const suffix = selectedType.suffix ? <OptionText>{selectedType.suffix}</OptionText> : null;

  const groupOptions = options =>
    options[0].group
      ? map(
          groupBy(options, option => option.group),
          (groupOptions, group) => ({
            label: group,
            options: map(groupOptions, option => option),
          })
        )
      : options;

  const repositoryOptions = options => {
    return map(
      groupBy(options, option => option.group),
      (groupOptions, group) => ({
        label: group,
        options: map(groupOptions, option => ({
          ...option,
          tip: option.serverUrl && <RepositoryInfoTooltip item={option} />,
        })),
      })
    );
  };

  const values = valueSettings.values.map(rulesService.convertOption);
  const isMultiAndNotWildcard = isMultiValue && !rulesService.isWildcard(selectedType, values);
  const isEmptySelectionAllowed = selectedType.allowEmptySelection;
  const valueOptions = rulesService.resolveOptions(selectedType, selectedSubType);
  const [inhibitValidationError, setInhibitValidationError] = useState(false);

  useEffect(() => {
    setInhibitValidationError(false);
  }, [validationError]);

  const typeOptions =
    selected?.type === 'Repository' ? (
      <RepositorySelect
        data-test-marker="rule-portion-value"
        data-value={selected.values[0]}
        isDisabled={disabled}
        isMulti={isMultiAndNotWildcard}
        isOptionDisabled={option => option.disabled}
        value={isMultiAndNotWildcard ? values : values[0]}
        options={repositoryOptions(valueOptions)}
        onChange={selectedItems =>
          rulesService.onMultiSelectOptionChange({
            newSelectedItems: selectedItems,
            selectedTypeSettings: selectedType,
            isMulti: isMultiAndNotWildcard,
            isAllowEmptySelection: isEmptySelectionAllowed,
            currentValues: values,
            currentSelected: selected,
            valueOptions,
            setSelection,
          })
        }
      />
    ) : valueOptions ? (
      <StyledTooltip>
        <StyledSelect
          data-test-marker="rule-portion-value"
          data-value={selected.values[0]}
          isDisabled={disabled}
          isMulti={isMultiAndNotWildcard}
          isOptionDisabled={option => option.disabled}
          value={isMultiAndNotWildcard ? values : values[0]}
          options={groupOptions(valueOptions)}
          onChange={selectedItems =>
            rulesService.onMultiSelectOptionChange({
              newSelectedItems: selectedItems,
              selectedTypeSettings: selectedType,
              isMulti: isMultiAndNotWildcard,
              isAllowEmptySelection: isEmptySelectionAllowed,
              currentValues: values,
              currentSelected: selected,
              valueOptions,
              setSelection,
            })
          }
        />
      </StyledTooltip>
    ) : selectedType.type === 'text' || selectedSubType?.type === 'text' ? (
      <StyledTooltip tip={disabled && selected.values[0]}>
        <StyledInput
          data-test-marker="rule-portion-value"
          data-value={selected.values[0]}
          shortinput={
            selectedType.subtype === 'number' || selectedSubType?.subtype === 'number' ? 1 : 0
          }
          disabled={disabled}
          value={selected.values[0]}
          onChange={event =>
            setSelection({
              ...pick(selected, ['type', 'subType']),
              values: [event.currentTarget.value],
            })
          }
          placeholder={selectedType.placeholder}
          invalid={isEmpty(selected.values[0])}
        />
      </StyledTooltip>
    ) : selectedType.type === 'expression' ? (
      <StyledExpressionEditor
        data-test-marker="rule-portion-value"
        data-value={selected.values[0]}
        disabled={disabled}
        value={selected.values[0]}
        onValueChange={newValue => {
          setSelection({
            ...pick(selected, ['type', 'subType']),
            values: [newValue],
          });

          setInhibitValidationError(true);
        }}
        highlights={
          validationError?.startOffset !== undefined && !inhibitValidationError
            ? [[validationError?.startOffset, validationError?.endOffset]]
            : []
        }
      />
    ) : null;

  const createSubTypes = subTypes => {
    if (isEmpty(subTypes)) {
      return [];
    }
    return subTypes[0].group
      ? map(
          groupBy(subTypes, type => type.group),
          (groupItemsTypes, group) => ({
            label: group,
            options: map(groupItemsTypes, itemType => rulesService.convertOption(itemType)),
          })
        )
      : subTypes.map(subtype => rulesService.convertOption(subtype));
  };

  const subTypes = selectedSubType ? (
    <ReactSelectWrapper
      isDisabled={disabled}
      isOptionDisabled={option => option.disabled}
      value={rulesService.convertOption(selectedSubType)}
      options={createSubTypes(selectedType.subTypes)}
      onChange={newSelection =>
        setSelection({
          type: selected.type,
          subType: newSelection.value,
          values: [
            rulesService.convertValueOnSubTypeChange(selected, newSelection, options, valueOptions),
          ],
        })
      }
    />
  ) : null;

  const optionTypes = keys(options);
  optionTypes.sort((type1, type2) =>
    options[type1].displayName.toLowerCase().localeCompare(options[type2].displayName.toLowerCase())
  );

  const selectOptions = options[optionTypes[0]].group
    ? map(
        groupBy(optionTypes, type => options[type].group),
        (groupItemsTypes, group) => ({
          label: group,
          options: map(groupItemsTypes, itemType => ({
            value: itemType,
            label: options[itemType].displayName,
            disabled: options[itemType].disabled,
          })),
        })
      )
    : optionTypes.map(optionType => ({
        value: optionType,
        label: options[optionType].displayName,
        disabled: options[optionType].disabled,
      }));
  const type =
    optionTypes.length > 1 ? (
      <StyledSelect
        data-test-marker="rule-portion-type"
        data-value={selected.type}
        isDisabled={disabled}
        wide={typeOptions === null}
        value={{
          value: selected.type,
          label: selectedType.displayName,
          tip: selectedType.tip,
          disabled: Boolean(selectedType.disabled),
        }}
        options={selectOptions}
        isOptionDisabled={option => option.disabled}
        onChange={newSelection => {
          const newDefaultSelection = rulesService.getDefaultOptionWithType(
            options,
            newSelection.value
          );
          setSelection({ ...newDefaultSelection, values: [newDefaultSelection.value] });
        }}
      />
    ) : (
      <StyledFirstLabel data-test-marker="rule-portion-type" data-value={selected.type}>
        {selectedType.displayName}
      </StyledFirstLabel>
    );

  const filterValueAny = propertiesList =>
    propertiesList.filter(property => selected.value !== 'any' || !property.excludeAny);
  let additionalProperties, additionalPropertiesType;
  if (!isNil(selectedValue.additionalProperties)) {
    additionalProperties = filterValueAny(selectedValue.additionalProperties);
    additionalPropertiesType = AdditionalPropertiesType.TypeValue;
  } else if (!isNil(selectedType.additionalProperties)) {
    additionalProperties = filterValueAny(selectedType.additionalProperties);
    additionalPropertiesType = AdditionalPropertiesType.Type;
  }

  const showPropertiesModifiers =
    !disabled &&
    rulesService.shouldShowPropertiesModifiers(additionalProperties, selected, selectedType);

  const generateOptionInput = (property, propertySettings, index) => {
    switch (property.type) {
      case 'Module':
        return (
          <StyledModuleInput
            propertySettings={propertySettings}
            selectedValue={selectedValue}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );

      case 'StorageBucket':
        return (
          <StyledStorageBucketInput
            propertySettings={propertySettings}
            selectedValue={selectedValue}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );
      case 'SecretCategory':
        return (
          <MultiSelectInput
            propertySettings={propertySettings}
            items={selectedType?.categories}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );

      case 'SecretType':
        return (
          <MultiSelectInput
            propertySettings={propertySettings}
            items={selectedType?.secretTypes}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );

      case 'FileClassification':
        return (
          <MultiSelectInput
            propertySettings={propertySettings}
            items={selectedType?.fileClassifications}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );

      case 'ExcludeInclude':
        return (
          <MultiSelectInput
            propertySettings={propertySettings}
            items={selectedType?.excludeInclude}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );

      case 'Validity':
        return (
          <MultiSelectInput
            propertySettings={propertySettings}
            items={selectedType?.secretValidationResults}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );

      default:
        const additionalProps = selectedType.additionalProperties;
        let lookups = [];
        const additionalProp = find(additionalProps, ['key', property.type]);
        if (additionalProp) {
          lookups = additionalProp.options;
        }
        if (isEmpty(lookups)) {
          return (
            <StyledInput
              key={index}
              value={property.values[0]}
              disabled={disabled}
              onChange={event =>
                setProperty({
                  propertyIndex: index,
                  type: property.type,
                  values: [event.currentTarget.value],
                })
              }
            />
          );
        }
        return (
          <StyledAdditionalPropertiesInput
            propertySettings={propertySettings}
            selectedType={selectedType}
            property={property}
            disabled={disabled}
            setProperty={setProperty}
            index={index}
            key={index}
          />
        );
    }
  };

  const generateOption = (propertySettings, index) => {
    return (
      <ReactSelectWrapper
        key={`option-${index}`}
        isDisabled={disabled || (additionalProperties?.length ?? 0) < 2}
        value={rulesService.convertOption(propertySettings)}
        options={rulesService
          .getPropertiesForSelectedOption(additionalProperties, selected, selectedType)
          .map(option => rulesService.convertOption(option))}
        onChange={newSelection =>
          setProperty({
            propertyIndex: index,
            type: newSelection.value,
            values: rulesService.getDefaultPropertyValue('SecretCategory', selectedType),
          })
        }
      />
    );
  };
  const properties = isEmpty(additionalProperties) ? null : (
    <StyledProperties>
      <AdditionalPropertiesPrefix>
        {!isEmpty(selected.additionalProperties) && selectedType.additionalPropertiesPrefix}
      </AdditionalPropertiesPrefix>
      {map(selected.additionalProperties, (property, index) => {
        const propertySettings = find(additionalProperties, ['key', property.type]);
        const option = generateOption(propertySettings, index);
        let optionInput = null;
        if (
          additionalProperties &&
          !additionalProperties.some(property => property?.type === 'labelOnly')
        ) {
          optionInput = generateOptionInput(property, propertySettings, index);
        }
        const optionAndInput = propertySettings.inputBeforeOption
          ? [optionInput, option]
          : [option, optionInput];
        return (
          <HorizontalStack key={index}>
            {optionAndInput}
            {!disabled && (
              <DeleteButton
                onClick={modifications =>
                  removeProperty({ ...modifications, propertyIndex: index })
                }
              />
            )}
          </HorizontalStack>
        );
      })}
    </StyledProperties>
  );

  return (
    <StyledOption data-test-marker="rule-portion">
      <StyledOption.InputRow>
        {type}
        {selectedType.subTypeConjunction ? (
          <OptionText>{selectedType.subTypeConjunction}</OptionText>
        ) : null}
        {subTypes}
        {optionsConjunction}
        {typeOptions}
        {suffix}
        {showPropertiesModifiers && (
          <CircleButton
            variant="tertiary"
            size="medium"
            onClick={() => addProperty({ type: additionalPropertiesType })}
            testMarker="addOption">
            <SvgIcon name="Plus" size="xsmall" />
          </CircleButton>
        )}
        {properties}
        {!disabled && !isSingleOption && removeOption && <DeleteButton onClick={removeOption} />}
      </StyledOption.InputRow>
      {validationError && (
        <StyledOption.ErrorLine>{validationError.message}</StyledOption.ErrorLine>
      )}
    </StyledOption>
  );
};

export default Option;

const AdditionalPropertiesPrefix = styled.span`
  padding-left: 1rem;
`;
