import { useCallback } from 'react';
import { TextareaControl } from '@src-v2/components/forms/form-controls';
import { SvgIcon } from '@src-v2/components/icons';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { OverrideModal } from '@src-v2/containers/action-modals/override-risk/override-modal';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { pluralFormat } from '@src-v2/utils/number-utils';

export function AcceptRisksModal({ data, onClose, ...props }) {
  const { risks, session, rbac } = useInject();
  const risksCount = data.length;

  const canEdit = rbac.canEdit(resourceTypes.RiskStatus);

  const handleSubmit = useCallback(
    async ({ acceptReasonText }) => {
      await risks.bulkAcceptRisks({ riskTriggers: data, acceptReasonText });

      const riskOverrideData = {
        reason: acceptReasonText,
        riskLevel: 'Accepted',
        riskStatus: 'Accepted',
        user: session.username,
        timestamp: new Date(),
      };

      data.forEach(item => risks.modifyRiskLevel(item, 'Accepted', riskOverrideData));
      onClose?.();
    },
    [data, onClose]
  );

  return (
    <OverrideModal
      {...props}
      disabledSubmitButton={!canEdit}
      onSubmit={handleSubmit}
      onClose={onClose}
      title={
        <>
          <SvgIcon name="Accept" />
          <Heading>Accept {pluralFormat(risksCount, 'Risk')}</Heading>
        </>
      }>
      <Paragraph>
        If {pluralFormat(risksCount, 'this is a risk', 'these are risks')} you are willing to accept
        - we won't consider {pluralFormat(risksCount, 'it', 'them')} in the risk score
      </Paragraph>
      <TextareaControl name="acceptReasonText" placeholder="I am accepting this risk because..." />
    </OverrideModal>
  );
}
