import { formatDistance } from 'date-fns';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { ActivityIndicator } from '@src-v2/components/activity-indicator';
import { MotionAnimation } from '@src-v2/components/animations/motion-animation';
import { Badge } from '@src-v2/components/badges';
import { Card } from '@src-v2/components/cards';
import { ClampText } from '@src-v2/components/clamp-text';
import { RiskScoreWidget } from '@src-v2/components/risk-score-widget';
import { RiskDetails } from '@src-v2/components/risk/risk-details';
import { useRiskProfile } from '@src-v2/components/risk/risk-utils';
import { ScrollSync } from '@src-v2/components/scroll-sync';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { SubHeading3 } from '@src-v2/components/typography';
import { AttackSurfaceSummaryChips } from '@src-v2/containers/profiles/attack-surface-summary-chips';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { formatDate } from '@src-v2/utils/datetime-utils';
import { preventDefault } from '@src-v2/utils/dom-utils';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { ActiveContributorsWidget } from '@src/blocks/ConsumableInfiniteScroll/blocks/ActiveContributorsWidget';
import { DetailsHeading } from '@src/blocks/ConsumableInfiniteScroll/blocks/DetailsBlocks';
import RiskyChangesWidget from '@src/blocks/ConsumableInfiniteScroll/blocks/RiskyChangesWidget';
import TopLanguagesWidget from '@src/blocks/ConsumableInfiniteScroll/blocks/TopLanguagesWidget';
import { ProfileFilterResults } from './ProfileFilterResults';

export default ({ profile, filtersMetadata }) => {
  const { pathname } = useLocation();

  const riskProfile = useRiskProfile(profile);

  const { application } = useInject();

  const subtitle = useMemo(() => {
    switch (pathname) {
      case '/profiles/repositories':
        return profile.isPublic ? 'Public' : 'Private';
      case '/profiles/applications':
        return profile.isModuleBased
          ? addInterpunctSeparator('Modules Based Application', 'Partial Support')
          : null;
      default:
        return null;
    }
  }, [profile, pathname]);

  const filterResults = useMemo(
    () =>
      profile.filtersResults.map(({ key, group, ...rest }) => ({
        key,
        ...rest,
        ...filtersMetadata[`${group}.${key}`],
      })),
    [profile.filtersResults, filtersMetadata]
  );

  const profileStatusTooltipContent = useMemo(() => {
    const rawDate = profile.isActive ? profile.activeSince : profile.lastActivity;
    if (profile.isActive) {
      return `In development for ${formatDistance(new Date(rawDate), new Date())}`;
    }
    if (profile.lastActivity) {
      return `Inactive since ${formatDate(new Date(rawDate), 'daily')}`;
    }
    return `No recorded activity`;
  }, [profile]);

  return (
    <ProfileCard
      to={`${pathname}/${profile.key}`}
      data-test-marker="profile-card"
      data-test-id={profile.key}>
      <MainContent data-test-marker="profile-main">
        <ProfileHeader>
          <Description>
            <Title data-test-marker="profile-card-name">
              <ClampText>{profile.name}</ClampText>
              {!profile.profileCalculationComplete && !profile.repository?.firstCycleCompleted && (
                <Tooltip content="Initial learning is in progress">
                  <LearningContainer>
                    <MotionAnimation size={3} width={2} margin={3} />
                  </LearningContainer>
                </Tooltip>
              )}
              {profile.profileCalculationComplete &&
                profile.profileType === 'ApplicationProfile' && (
                  <ActivityIndicator
                    active={profile.isActive}
                    content={profileStatusTooltipContent}
                  />
                )}
            </Title>

            {profile.description && (
              <SubHeading3>
                <ClampText>{profile.description}</ClampText>
              </SubHeading3>
            )}

            {profile.profileType === 'RepositoryProfile' && profile.provider !== 'Perforce' && (
              <BranchName>
                {profile.repository?.referenceName}
                {profile.tag && <Badge>{profile.tag}</Badge>}
              </BranchName>
            )}
          </Description>
          {subtitle && (
            <SubTitle>
              <Badge>
                <ClampText>{subtitle}</ClampText>
              </Badge>
            </SubTitle>
          )}
        </ProfileHeader>
        <ProfileDetails>
          <TopLanguagesWidget
            isModuleBased={profile.isModuleBased}
            languagePercentages={profile.languagePercentages}
          />
          <ActiveContributorsWidget
            isModuleBased={profile.isModuleBased}
            activeContributorCount={profile.statistics.activeDeveloperCount}
            contributorCount={profile.statistics.developerCount}
            contributorData={profile.statistics.activeDeveloperData}
          />
          <RiskyChangesWidget riskyChanges={profile} />
          <RiskDetails profile={riskProfile} onClick={preventDefault} />
          {application.isFeatureEnabled(FeatureFlag.RiskScore) && (
            <RiskScoreWidget profile={riskProfile} />
          )}
        </ProfileDetails>
      </MainContent>

      <CustomScrollSync as={DynamicSection} onClick={preventDefault}>
        <AdditionalDetails data-test-marker="profile-additional">
          <ProfileAttackSurfaceDetails profile={profile} />
          {filterResults.length > 0 && <ProfileFilterResults results={filterResults} />}
        </AdditionalDetails>
      </CustomScrollSync>
    </ProfileCard>
  );
};

const LearningContainer = styled.span`
  display: inline-block;
  margin-left: 12px;
  vertical-align: text-bottom;
`;

const ProfileCard = styled(Card)`
  display: flex;
  flex: 1 1 auto;
  padding: 0;
  overflow: hidden;
`;

const Title = styled.h2`
  max-width: 180rem;
  display: flex;
  margin-bottom: 8px;
  align-items: center;
  font-size: 28px;
  font-weight: 500;
  word-break: break-word;
  gap: 2rem;
`;

const SubTitle = styled.div`
  max-width: 70rem;
`;

const MainContent = styled.div`
  min-width: 55rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1 1 auto;
  padding: 8rem;
  border-radius: 5rem 0 0 5rem;
`;

const ProfileHeader = styled.header`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 6rem;
`;
const ProfileDetails = styled.div`
  display: flex;
  align-items: flex-end;
  min-width: 576px;
  max-width: 704px;
  margin-top: 44px;
  justify-content: space-between;
  gap: 32px;
`;

export const ProfileWidget = styled.div`
  min-width: 25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

export const ProfileWidgetLabel = styled.div`
  color: var(--default-text-color);
  font-size: var(--font-size-xs);
  font-weight: 300;
  white-space: nowrap;
  line-height: 1;
`;

const DynamicSection = styled.aside`
  display: flex;
  width: 46%;
  min-width: 376px;
  max-height: 300px;
  flex-direction: column-reverse;
  font-size: var(--font-size-s);
  font-weight: 300;
  line-height: 5rem;
  background-color: var(--color-blue-gray-20);
  border-radius: 0 3rem 3rem 0;
  cursor: default;
  overflow: auto;
  z-index: 2;

  &:hover::-webkit-scrollbar {
    opacity: 1;
  }

  &::-webkit-scrollbar {
    background-color: transparent;
    transition: opacity 400ms;
    opacity: 0;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 100vmax;
    border: 1rem solid var(--color-blue-gray-20);
    background-color: var(--color-blue-gray-30);
  }
`;

const AdditionalDetails = styled.div`
  display: flex;
  height: min-content;
  padding: 5rem;
  align-content: flex-end;
  flex-wrap: wrap;
  gap: 2rem;
`;

const Description = styled.div`
  max-width: 100%;
  display: flex;
  flex-direction: column;
`;

const BranchName = styled.span`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const ProfileAttackSurfaceDetails = ({ profile, ...props }) => (
  <>
    <DetailsHeading>Attack Surface Summary</DetailsHeading>
    <AttackSurfaceSummaryChips {...props} attackSurfaceSummary={profile} />
  </>
);

const CustomScrollSync = styled(ScrollSync)`
  width: 120rem;
  min-width: 120rem;
  max-width: 120rem;
`;
