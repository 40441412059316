import _ from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import ApiiroLogoSmall from '@src-v2/assets/apiiro-logo-small.svg';
import { Button } from '@src-v2/components/button-v2';
import { Input } from '@src-v2/components/forms';
import { Heading } from '@src-v2/components/typography';
import { useInject, useQueryParams } from '@src-v2/hooks';

export default observer(function LoginForm() {
  const { session } = useInject();
  const {
    queryParams: { returnUrl = '/' },
  } = useQueryParams();
  const {
    register,
    setError,
    clearErrors,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({ mode: 'onBlur' });

  const handleChange = useMemo(() => _.once(() => clearErrors('request')), [errors.request]);

  const requestLogin = useCallback(
    ({ username, password }) =>
      session.login({ username, password, returnUrl }).catch(error =>
        setError('request', {
          type: error.name,
          message: error.response?.data?.message ?? 'Sign in failed',
        })
      ),
    [session, returnUrl]
  );

  return (
    <Container>
      <LogoContainer>
        <ApiiroLogo />
      </LogoContainer>
      <Form onSubmit={handleSubmit(requestLogin)} onChange={handleChange}>
        <Heading>Sign In</Heading>
        {session.requireUsername && (
          <Label>
            Email address
            <Input
              {...register('username', { required: true })}
              autoComplete="username"
              autoFocus
            />
          </Label>
        )}
        <Label>
          Password
          <Input
            type="password"
            {...register('password', { required: true })}
            autoFocus={!session.requireUsername}
            autoComplete="current-password"
          />
          {errors.request && <ErrorMessage>{errors.request.message}</ErrorMessage>}
        </Label>
        <Button type="submit" disabled={isSubmitting} variant="primary">
          Sign In
        </Button>
      </Form>
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  width: 100rem;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  padding: 7.5rem 0;
  border-bottom: 0.25rem solid var(--color-blue-gray-30);
`;

const ApiiroLogo = styled(ApiiroLogoSmall)`
  height: 10rem;
  width: 10rem;
  margin: auto;
`;

const Form = styled.form`
  display: flex;
  padding: 5rem 11rem 10rem;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  flex-grow: 1;

  ${Heading} {
    font-size: var(--font-size-m);
    text-align: center;
    margin-bottom: 7rem;
  }

  ${Button} {
    margin-top: 5rem;
  }
`;

const Label = styled.label`
  position: relative;
  margin-bottom: 2rem;
  font-size: var(--font-size-s);
  font-weight: 600;
  line-height: 4rem;

  ${Input} {
    height: 11rem;
    margin: 1rem 0 2rem;
    line-height: 11rem;
    vertical-align: middle;
  }
`;

// TODO use an ErrorMessage component once we create it :)
const ErrorMessage = styled.div`
  position: absolute;
  width: 100%;
  padding: 1rem 2rem;
  border-radius: 1rem;
  color: var(--color-red-50);
  background-color: var(--color-red-10);
`;
