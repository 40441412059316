import _ from 'lodash';
import { useCallback, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { CircleButton } from '@src-v2/components/button-v2';
import { SvgIcon } from '@src-v2/components/icons';
import { Checklists } from '@src-v2/components/marketing/checklists';
import { Modal } from '@src-v2/components/modals';
import { Heading1, Link, SubHeading2 } from '@src-v2/components/typography';
import { useInject } from '@src-v2/hooks';

export function UpgradeRequestModal({
  title = undefined,
  description = undefined,
  onClose,
  ...props
}) {
  const { subscription, toaster } = useInject();
  const [isRequestSuccess, setIsRequestSuccess] = useState(false);
  const handleClick = useCallback(async () => {
    try {
      await subscription.requestPlanUpgrade();
      setIsRequestSuccess(true);
    } catch (error) {
      setIsRequestSuccess(false);
      toaster.error('Failed sending plan upgrade request');
    }
  }, [subscription, setIsRequestSuccess]);

  return (
    <UpgradeModal {...props} onClose={onClose}>
      <CircleButton onClick={onClose} size="large" variant="tertiary">
        <SvgIcon name="CloseLarge" size="large" />
      </CircleButton>
      <Content>
        {!isRequestSuccess ? (
          <>
            <SwitchHeadingByRoute title={title} description={description} />
            <Checklists onAction={handleClick} />
          </>
        ) : (
          <StatusMessage />
        )}
      </Content>
    </UpgradeModal>
  );
}

const UpgradeModal = styled(Modal)`
  position: relative;
  display: flex;
  justify-content: center;
  width: 220rem;
  height: 144rem;
  border-radius: 4rem;
  background: linear-gradient(90deg, #212a3f 0%, #3d4f77 100%);

  > ${CircleButton} {
    position: absolute;
    inset: 10rem 10rem auto auto;
    width: 8rem;
    height: 8rem;
    color: var(--color-blue-gray-50);
    cursor: pointer;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 7rem;

  > ${Heading1} {
    font-size: var(--font-size-xxl);
    text-align: center;
    line-height: 0;
    white-space: pre-wrap;
    color: var(--color-white);
    margin-bottom: 0;
  }

  > ${SubHeading2} {
    display: flex;
    align-items: center;
    font-weight: 300;
    text-align: center;
    white-space: pre-wrap;
    color: var(--color-white);
    padding: 0 10rem;
    margin-bottom: 0;
  }
`;

const StatusMessage = styled(({ ...props }) => {
  const { subscription } = useInject();
  return (
    <div {...props}>
      <Heading1>
        Thank you, {subscription.account?.displayName ?? ''}!{'\n'}
        Your upgrade request was successfully sent
      </Heading1>
      <SubHeading2>
        Our team will contact you soon with the relevant pricing plan{'\n'}at{' '}
        {subscription.account?.email ?? ''}
      </SubHeading2>
    </div>
  );
})`
  position: relative;
  bottom: 6rem;
  display: flex;
  flex-direction: column;
  color: var(--color-blue-gray-15);
  text-align: center;
  white-space: pre;
  gap: 4rem;

  > ${Heading1} {
    color: var(--color-white);
  }

  > ${SubHeading2} {
    color: var(--color-blue-50);

    ${Link} {
      color: var(--color-blue-45);
      text-decoration: underline;
    }
  }
`;

function SwitchHeadingByRoute({ title, description }) {
  const { subscription } = useInject();

  return title && description ? (
    <>
      <Heading1>{title}</Heading1>
      <SubHeading2>{description}</SubHeading2>
    </>
  ) : (
    <Switch>
      {subscription.limitations.productsMaxCount && (
        <Route path="/profiles">
          <Heading1>Upgrade to set an unlimited number of applications</Heading1>
          <SubHeading2>
            In the free trial you can set up to {subscription.limitations.productsMaxCount}{' '}
            applications.
          </SubHeading2>
        </Route>
      )}

      {subscription.limitations.releasesMaxCount && (
        <Route path="/releases">
          <Heading1>Upgrade to set an unlimited number of release risk assessments</Heading1>
          <SubHeading2>
            In the free trial you can set up to {subscription.limitations.releasesMaxCount} release
            assessments.
          </SubHeading2>
        </Route>
      )}

      {subscription.limitations.limitGovernanceRules && (
        <Route path="/governance">
          <Heading1>Upgrade to create and edit governance poilcies</Heading1>
          <SubHeading2>Trial enables view mode only.</SubHeading2>
        </Route>
      )}

      {subscription.limitations.limitWorkflows && (
        <Route path="/workflows">
          <Heading1>Upgrade to create unlimited risk-based workflows</Heading1>
          <SubHeading2>
            Trial enables commits and ticketing projects workflows only.{'\n'}
            Upgrade to trigger unlimited workflows, including commenting on pull-requests.
          </SubHeading2>
        </Route>
      )}

      {!_.isEmpty(subscription.limitations.limitedConnectorGroups) && (
        <Route path="/connectors">
          <Heading1>Upgrade to integrate all types of connectors</Heading1>
          <SubHeading2>
            Trial enables SCMs & ticketing systems only. Upgrade to improve your vulnerabilities
            prioritization, internet facing assets management, and more.
          </SubHeading2>
        </Route>
      )}

      {subscription.limitations.limitRbac && (
        <Route path="/settings/access-permissions/roles">
          <Heading1>Upgrade to enable role-based access control</Heading1>
          <SubHeading2>Upgrade to create and manage roles and permissions.</SubHeading2>
        </Route>
      )}

      {subscription.limitations.teamsMaxCount && (
        <Route path="/users">
          <Heading1>Upgrade to create all the teams you need!</Heading1>
          <SubHeading2>
            The Trial Plan is limited to {subscription.limitations.teamsMaxCount} teams.{'\n'}
            Upgrade and Expand Your Application Risk Management Program
          </SubHeading2>
        </Route>
      )}

      <Route>
        <Heading1>Remediate critical risks at the design, commit, PR & CI/CD</Heading1>
        <SubHeading2>Get visibility & context to eliminate noisy alerts</SubHeading2>
      </Route>
    </Switch>
  );
}
