import { includesValue } from '@src-v2/components/filters/menu-control/filters-menu';
import { SubType } from '@src-v2/containers/connectors/connections/catalog/connectors-provider-layouts';
import { MonitorStatus } from '@src-v2/data/monitor-status';
import { transformFilterGroups } from '@src-v2/data/transformers';
import { Filter } from '@src-v2/hooks/use-filters';
import { Analytics } from '@src-v2/services/analytics';
import { ApiClient } from '@src-v2/services/api-client';
import { Application } from '@src-v2/services/application';
import { AsyncCache } from '@src-v2/services/async-cache';
import { Connection } from '@src-v2/types/connector/connectors';
import { ProviderRepositoryDataType } from '@src-v2/types/multi-branch';
import { ProviderGroup } from '@src-v2/types/providers/provider-group';
import { ProviderType } from '@src-v2/types/providers/provider-type';
import { modify } from '@src-v2/utils/mobx-utils';
import { uri } from '@src-v2/utils/template-literals';

export type ArtifactType = {
  name: string;
  packageType: string;
  description: string;
  lastCandidateByComponentsFullRefresh: string;
  key: string;
  id: string;
  syncSequence: number;
  createdAt: string;
  nameLower: string;
  type: string;
  serverUrl: string;
  server: Connection;
  providerExtensionData: any;
  url: string;
  isMonitored: boolean;
  isNotMonitored: boolean;
  isIgnored: boolean;
  monitorStatus: string;
  monitorStatusIntValue: number;
  lastMonitoringChangeTimestamp: string;
  ignoredBy: null;
  ignoreReason: null;
  relevancyStatus: string;
  interestScore: number;
  isPrivate: null;
  isPublic: boolean;
  isArchived: boolean;
  isNotArchived: boolean;
  shouldForceRefresh: boolean;
  consumableType: string;
};
export type SummaryType = {
  isRelated: boolean;
  key: string;
  label: string;
  monitored: number;
  total: number;
};

export class Connectors {
  #client: ApiClient;
  #asyncCache: AsyncCache;
  #application: Application;
  #analytics: Analytics;

  constructor({ apiClient, asyncCache, application, analytics }) {
    this.#client = apiClient;
    this.#asyncCache = asyncCache;
    this.#application = application;
    this.#analytics = analytics;
  }

  async createServer(serverData) {
    const response = await this.#client.post('servers', serverData);
    await this.#application.fetchIntegrations();
    this.#asyncCache.invalidateAll(this.getProviderGroups);
    this.#asyncCache.invalidateAll(this.getProviderTypes);
    return response;
  }

  async updateServer(serverData) {
    const response = await this.#client.put(uri`servers/${serverData.url}`, serverData);
    this.#asyncCache.invalidateAll(this.getProviderTypes);
    this.#asyncCache.invalidateAll(this.getConnection);
    return response;
  }

  async deleteServer(serverUrl) {
    const response = await this.#client.delete(uri`servers/${serverUrl}`);
    await this.#application.fetchIntegrations();
    this.#asyncCache.invalidateAll(this.getProviderGroups);
    this.#asyncCache.invalidateAll(this.getProviderTypes);
    return response;
  }

  updateBranchLabel({ key, tag }) {
    return this.#client.put(`repositories/${key}/tags`, { tag });
  }

  setBranchMonitor({ key, name }) {
    const data = this.#client.post(`providerRepositories/${key}/branches`, { branchName: name });
    this.#asyncCache.invalidateAll(this.searchConnectionRepositories);
    return data;
  }

  getBranchMonitor({ key, name }) {
    return this.#client.get(`providerRepositories/${key}/branches/${encodeURIComponent(name)}`);
  }

  setBranchUnmonitor({ key }) {
    const data = this.#client.put(`repositories/${key}`, { monitorStatus: 'NotMonitored' });
    this.#asyncCache.invalidateAll(this.searchConnectionRepositories);
    return data;
  }

  async setMultiBranchChanges({ key, data }) {
    await this.#client.put(`providerRepositories/${key}/manage`, data);
    this.#asyncCache.invalidateAll(this.searchConnectionRepositories);
    this.#asyncCache.invalidate(this.getProviderRepositoryData, { key });
  }

  async getProviderGroups(): Promise<ProviderGroup[]> {
    const groups = await this.#client.get('providers/groups');
    return this.filterDemoAndBetaConnectors(groups);
  }

  async getRepositoriesGroups({ key }) {
    return await this.#client.get(`providerRepositories/${key}/repositoriesGroups`);
  }

  async getProviderTypes(): Promise<ProviderType[]> {
    const types = await this.#client.get('providers/types');
    const groups = types.map((group: ProviderType) => ({
      ...group,
      providerGroups: this.filterDemoAndBetaConnectors(group.providerGroups),
      subTypes:
        group.subTypes?.map((type: SubType) => ({
          ...type,
          providerGroups: this.filterDemoAndBetaConnectors(type.providerGroups),
        })) ?? [],
    }));
    return groups.filter((group: ProviderType) => Boolean(group.providerGroups.length));
  }

  async getConnection({ key }): Promise<Connection> {
    return await this.#client.get(`servers/v2/${key}`);
  }

  async getConnectionSummary({ key }): Promise<SummaryType[]> {
    const consumableSummaries = await this.#client.get(`servers/v2/${key}/summary`);
    const summaries = consumableSummaries.filter((consumable: SummaryType) => consumable.isRelated);

    // hides projects tab on other providers
    if (summaries.length > 1) {
      return summaries.filter((summary: SummaryType) => summary.key !== 'FindingsReports');
    }

    return summaries;
  }

  getRepository({ key }) {
    return this.#client.get(`repositories/${key}`);
  }

  getMonitorAllEffects({ serverUrl, shouldMonitor }) {
    return this.#client.get(uri`servers/${serverUrl}/monitor/affected`, {
      params: { isMonitored: shouldMonitor },
    });
  }

  getDeleteConnectionEffects({ serverUrl }) {
    return this.#client.get(uri`servers/${serverUrl}/affected`);
  }

  getRepositoriesFilterOptions({
    isSingleConnection,
    serverUrl,
  }: {
    isSingleConnection: boolean;
    serverUrl?: string;
  }): Promise<Filter[]> {
    return this.#client
      .get('repositories/filterOptions', { params: { isSingleConnection, serverUrl } })
      .then(filterGroups => filterGroups.map(transformFilterGroups));
  }

  getClustersFilterOptions() {
    return this.#client
      .get('clusters/filterOptions', { params: { isSingleConnection: true } })
      .then(filterGroups => filterGroups.map(transformFilterGroups));
  }

  getArtifactsFilterOptions() {
    return this.#client
      .get('artifactRepositories/filterOptions')
      .then(filterGroups => filterGroups.map(transformFilterGroups));
  }

  getFindingsReportsFilterOptions({ serverUrl }) {
    return this.#client
      .get('findingsReportsProjects/filterOptions', { params: { serverUrl } })
      .then(filterGroups => filterGroups.map(transformFilterGroups));
  }

  getProjectsFilterOptions() {
    return this.#client
      .get('projects/filterOptions', { params: { isSingleConnection: true } })
      .then(filterGroups => filterGroups.map(transformFilterGroups));
  }

  getApiGatewayFilterOptions() {
    return this.#client
      .get('apiGateways/filterOptions')
      .then(filterGroups => filterGroups.map(transformFilterGroups));
  }

  getProviderRepositoryBranches({ key, searchTerm, pageSize, startPage }) {
    return this.#client.get(`providerRepositories/${key}/branches`, {
      params: {
        searchTerm,
        pageSize,
        startPage,
      },
    });
  }

  getProviderRepositoryData({ key }: { key: string }): Promise<ProviderRepositoryDataType> {
    return this.#client.get(`providerRepositories/${key}`);
  }

  getMonitorNewStatus({ key }) {
    return this.#client.get(`servers/${key}/monitorStatus`);
  }

  async redirectToOAuthConsentUrl(providerKey, body = {}) {
    window.location.href = await this.#client.post(
      `integrations/${providerKey}/authenticate`,
      body
    );
  }

  async setServerMonitorAll({
    serverUrl,
    shouldMonitor,
    ignoredIncluded,
  }: {
    serverUrl: string;
    shouldMonitor?: boolean;
    ignoredIncluded?: boolean;
  }) {
    // @ts-expect-error
    this.#asyncCache.invalidate(this.getProviderTypes);
    return await this.#client.put(uri`servers/${serverUrl}/monitor`, {
      monitorStatus: shouldMonitor ? 'Monitored' : 'NotMonitored',
      ignoredIncluded,
    });
  }

  async setServerMonitorNewRepositoriesGroups({
    serverUrl,
    groupsKeys,
    shouldMonitor,
    monitorArchived,
  }) {
    await this.#client.put(uri`servers/${serverUrl}/monitorNewForRepositoriesGroups`, {
      groupsKeys,
      monitorNewBin: { monitorNew: shouldMonitor, monitorArchived },
    });
    this.#asyncCache.invalidate(this.getMonitorNewStatus, { key: serverUrl });
  }

  async setServerMonitorNew({
    serverUrl,
    shouldMonitor,
    monitorArchived,
  }: {
    serverUrl: string;
    shouldMonitor: boolean;
    monitorArchived?: boolean;
  }) {
    await this.#client.put(uri`servers/${serverUrl}/monitorNew`, {
      monitorNew: shouldMonitor,
      MonitorArchived: monitorArchived,
    });
    // @ts-expect-error
    this.#asyncCache.invalidate(this.getProviderGroups);
    this.#asyncCache.invalidate(this.getMonitorNewStatus, { key: serverUrl });
  }

  async setServerPrivateKey({ serverUrl, file }) {
    const formData = new FormData();
    formData.append('privateKey', file);
    await this.#client.post(uri`servers/${serverUrl}/privateKey`, formData, {
      headers: { 'content-type': 'multipart/form-data' },
    });
    // @ts-expect-error
    this.#asyncCache.invalidate(this.getProviderGroups);
  }

  async getJiraServerSettings({ serverUrl }) {
    return await this.#client.get(`servers/jira/${serverUrl}/settings`);
  }

  async setJiraServerSettings({
    serverUrl,
    serverSettings,
  }: {
    serverUrl: string;
    serverSettings: any;
  }) {
    await this.#client.put(uri`servers/jira/${serverUrl}/settings`, serverSettings);
    this.#asyncCache.invalidate(this.getJiraServerSettings, { serverUrl });
  }

  async toggleIgnoredProviderRepository({ key, shouldIgnore, ignoreReason }) {
    const updatedData = await this.#client.put(`providerRepositories/${key}`, {
      monitorStatus: shouldIgnore ? MonitorStatus.Ignored : MonitorStatus.NotMonitored,
      ignoreReason,
    });

    this.#analytics.track(
      `Repository ${shouldIgnore ? MonitorStatus.Ignored : MonitorStatus.NotMonitored}`,
      { Success: Boolean(updatedData) }
    );
    return updatedData;
  }

  async bulkToggleIgnoredProviderRepositories({ data, shouldIgnore, ignoreReason }) {
    const keys = data.map(item => item.key);
    const excludedRepositories = await this.#client.put(`providerRepositories`, {
      monitorStatus: shouldIgnore ? MonitorStatus.Ignored : MonitorStatus.NotMonitored,
      keys,
      ignoreReason,
    });
    const repositoriesToModify = data.filter(
      repository => !includesValue(excludedRepositories, repository)
    );
    repositoriesToModify.forEach(repository => {
      modify(repository, { isIgnored: shouldIgnore, isMonitored: false });
    });

    this.#asyncCache.invalidateAll(this.searchConnectionRepositories);
  }

  async toggleMonitoredProviderRepository(data) {
    const monitorStatus = data.isMonitored ? MonitorStatus.NotMonitored : MonitorStatus.Monitored;
    try {
      const updatedData = await this.#client.put(`providerRepositories/${data.key}`, {
        monitorStatus,
      });
      this.#asyncCache.invalidate(this.getConnectionSummary, {
        key: encodeURIComponent(data.serverUrl),
      });

      if (updatedData) {
        modify(data, updatedData);
      }

      this.#analytics.track(`Repository ${monitorStatus}`, { Success: Boolean(updatedData) });
    } catch (error) {
      modify(data, { isMonitored: !data.isMonitored });
      throw error;
    }
  }

  async bulkToggleMonitoredFindingReports(repositories, shouldMonitor) {
    const keys = repositories.map(repository => repository.key);

    await this.#client.put(`findingsReportsProjects`, {
      keys,
      monitorStatus: shouldMonitor ? 'Monitored' : 'NotMonitored',
    });

    repositories.forEach(repository => {
      modify(repository, { isMonitored: shouldMonitor });
    });
  }

  async bulkToggleMonitoredProviderRepositories(repositories, shouldMonitor) {
    const keys = repositories.map(repository => repository.key);

    await this.#client.put(`providerRepositories`, {
      keys,
      monitorStatus: shouldMonitor ? 'Monitored' : 'NotMonitored',
    });

    repositories.forEach(repository => {
      modify(repository, { isMonitored: shouldMonitor, isIgnored: false });
    });
    this.#asyncCache.invalidateAll(this.searchConnectionRepositories);
    this.#asyncCache.invalidateAll(this.getConnectionSummary);
    this.#asyncCache.invalidateAll(this.getConnection);
    this.#asyncCache.invalidateAll(this.getMonitorNewStatus);
    this.#asyncCache.invalidateAll(this.getProviderGroups);
    await this.#application.fetchIntegrations();
  }

  async bulkToggleMonitoredProjects(projects, shouldMonitor) {
    const keys = projects.map(repository => repository.key);

    await this.#client.put(`projects`, {
      keys,
      monitorStatus: shouldMonitor ? 'Monitored' : 'NotMonitored',
    });

    projects.forEach(project => {
      modify(project, { isMonitored: shouldMonitor });
    });

    this.#asyncCache.invalidateAll(this.getConnectionSummary);
    this.#asyncCache.invalidateAll(this.getConnection);
    this.#asyncCache.invalidateAll(this.getMonitorNewStatus);
    this.#asyncCache.invalidateAll(this.getProviderGroups);
    await this.#application.fetchIntegrations();
  }

  async toggleMonitoredRepository({ key, shouldMonitor }) {
    const updatedData = await this.setMonitorStatus({
      key,
      shouldMonitor,
      controller: 'repositories',
    });

    this.#analytics.track(
      `Repository ${shouldMonitor ? MonitorStatus.Monitored : MonitorStatus.NotMonitored}`,
      { Success: Boolean(updatedData) }
    );
    return updatedData;
  }

  setMonitorStatus({ key, controller, shouldMonitor }) {
    return this.#client.put(`${controller}/${key}`, {
      monitorStatus: shouldMonitor ? MonitorStatus.Monitored : MonitorStatus.NotMonitored,
    });
  }

  async toggleMonitoredProject({ key, shouldMonitor, serverUrl }) {
    await this.setMonitorStatus({ key, shouldMonitor, controller: 'projects' });
    this.#asyncCache.invalidate(this.getConnectionSummary, { key: encodeURIComponent(serverUrl) });
  }

  toggleMonitoredArtifactRepository({ key, shouldMonitor }) {
    return this.setMonitorStatus({ key, shouldMonitor, controller: 'artifactRepositories' });
  }

  toggleMonitoredApiGateway({ key, shouldMonitor }) {
    return this.setMonitorStatus({ key, shouldMonitor, controller: 'apiGateways' });
  }

  toggleMonitoredFindingsReport({ key, shouldMonitor }) {
    return this.setMonitorStatus({ key, shouldMonitor, controller: 'findingsReportsProjects' });
  }

  async setProviderRepositoryStatus({ key, shouldMonitor, ignore, ignoreReason }) {
    const monitorStatus = ignore
      ? MonitorStatus.Ignored
      : shouldMonitor
        ? MonitorStatus.Monitored
        : MonitorStatus.NotMonitored;
    const success = Boolean(
      await this.#client.put(`providerRepositories/${key}`, { monitorStatus, ignoreReason })
    );
    this.#analytics.track(`Repository ${ignore ? 'Ignored' : monitorStatus}`, {
      Success: success,
    });
    return success;
  }

  searchProviderRepositories(params) {
    return this.#client
      .search('providerRepositories/search', params)
      .then(({ items, ...rest }) => ({
        ...rest,
        items,
      }));
  }

  filterDemoAndBetaConnectors(types) {
    return types.filter(
      type =>
        (!type.betaFeatureName ||
          this.#application.isFeatureEnabled(type.betaFeatureName) ||
          !type.isHidden) &&
        (this.#application.isDemo || !type.demoOnly)
    );
  }

  searchConnectionRepositories(params) {
    return this.#client.search(
      uri`servers/v2/${params.connectionUrl}/providerRepositories/search`,
      params
    );
  }

  searchConnectionProjects(params) {
    return this.#client
      .search(uri`servers/v2/${params.connectionUrl}/projects/search`, params)
      .then(({ items, ...rest }) => ({
        ...rest,
        items: items.map(item => ({
          ...item,
          isRecommended: item.interestScore > 0 && !item.isArchived,
        })),
      }));
  }

  searchConnection({ type, params }) {
    return this.#client.search(uri`servers/v2/${params.connectionUrl}/${type}/search`, params);
  }

  searchConnectionApiGateways(params) {
    return this.searchConnection({ type: 'apiGateways', params });
  }

  searchConnectionClusters(params) {
    return this.#client.search(uri`servers/v2/${params.connectionUrl}/clusters/search`, params);
  }

  searchConnectionArtifacts(params) {
    return this.#client.search(uri`servers/v2/${params.connectionUrl}/artifacts/search`, params);
  }

  searchConnectionNetworkBroker(params): Promise<{ count: number; items: any[]; total: number }> {
    return this.#client.get('networkBroker/hosts', params);
  }

  searchConnectionFindingsReports(params) {
    return this.#client.search(
      uri`servers/v2/${params.connectionUrl}/findingsReportsProjects/search`,
      params
    );
  }
}
