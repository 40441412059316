import _ from 'lodash';
import { ApplicationFormValues } from '@src-v2/containers/applications/creation-form/application-form-values';
import { PointsOfContactOptions } from '@src-v2/containers/profiles/points-of-contacts-fields';
import {
  EnrichedApplicationConfigurationResponse,
  FlatApplicationProfile,
} from '@src-v2/types/profiles/application-profile-response';
import { entries } from '@src-v2/utils/ts-utils';

export function convertAppConfigurationToFormValues({
  pointsOfContact,
  isInternetFacing,
  ...configuration
}: EnrichedApplicationConfigurationResponse): ApplicationFormValues {
  const groupedPointsOfContact = _.groupBy(pointsOfContact, 'jobTitle');

  return {
    ...configuration,
    isInternetFacing: isInternetFacing ? 'Yes' : 'No',
    pointsOfContact: entries(groupedPointsOfContact).map(([jobTitle, developers]) => ({
      developer: developers,
      jobTitle: { value: jobTitle, label: PointsOfContactOptions[jobTitle] },
    })),
  };
}

export function convertFormValuesToFlatApplicationProfile({
  pointsOfContact,
  repositories = [],
  projects = [],
  complianceRequirements = [],
  isInternetFacing,
  apiGateways: apiGatewayConfigurations = [],
  modules = [],
  modulesRepository,
  ...formValues
}: ApplicationFormValues): FlatApplicationProfile {
  const apiGateways = apiGatewayConfigurations.filter(
    config => Boolean(config.gateway) && Boolean(config.gatewayRoute)
  );

  let modulesGroup: FlatApplicationProfile['modulesGroup'] = null;
  if (modules?.length && modulesRepository) {
    const modulesRoots = modules.map(module => module.root);

    modulesGroup = {
      repositoryKey: modulesRepository.key,
      moduleKeys: modulesRoots.filter(
        root => !modulesRoots.some(otherRoot => otherRoot !== root && root.includes(otherRoot))
      ),
    };
  }

  return {
    ...formValues,
    isInternetFacing: isInternetFacing === 'Yes',
    key: formValues.key ?? crypto.randomUUID(),
    projectKeys: projects.map(project => project.key),
    repositoryKeys: repositories.map(repository => repository.key),
    modulesGroup,
    businessImpact: formValues.businessImpact?.value,
    deploymentLocation: formValues.deploymentLocation?.value,
    applicationType: formValues.applicationType?.value,
    estimatedUsersNumber: formValues.estimatedUsersNumber?.value,
    estimatedRevenue: formValues.estimatedRevenue?.value,
    complianceRequirements: complianceRequirements.map(option => option.value),
    apiGatewayKeys: apiGateways.filter(api => !_.isEmpty(api)).map(({ gateway }) => gateway.key),
    apisGroupKeys: apiGateways
      .filter(api => !_.isEmpty(api))
      .map(({ gatewayRoute }) => gatewayRoute.key),
    pointsOfContact: pointsOfContact?.flatMap(({ developer, jobTitle }) =>
      developer.map(item => ({
        representativeIdentityKeySha: item.identityKey,
        title: jobTitle.value,
      }))
    ),
  };
}
