import styled from 'styled-components';
import { Button, Variant } from '@src-v2/components/button-v2';
import { BaseIcon } from '@src-v2/components/icons';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { Link } from '@src-v2/components/typography';
import { autoIgnoreRiskLevel } from '@src/components/RiskIcon';

const MaterialChangeActions = styled(
  ({ materialChange, governanceRule, thenSubType, isReleaseContext, ...props }) => {
    let riskIcon;
    let addExploreButton = false;
    if (governanceRule.isAutoIgnored) {
      riskIcon = <RiskIcon riskLevel={autoIgnoreRiskLevel} />;
    } else if (governanceRule.risk !== 'None' && thenSubType !== 'Removed') {
      riskIcon = <RiskIcon riskLevel={governanceRule.risk} />;
      addExploreButton = true;
    }

    const shouldDisplayRiskLink =
      materialChange.riskLevel &&
      materialChange.riskLevel !== 'None' &&
      materialChange.repositoryKey &&
      materialChange.entityIdEncoded &&
      !isReleaseContext;

    const riskLink =
      shouldDisplayRiskLink &&
      `/profiles/repositories/${materialChange.repositoryKey}/risk/development/ruleTriggers/${materialChange.governanceRuleKey}/trigger/${materialChange.entityIdEncoded}__${materialChange.repositoryKey}`;

    return (
      <div {...props}>
        {riskLink ? (
          addExploreButton ? (
            <ExploreContainer>
              {riskIcon}
              <Button to={riskLink} variant={Variant.SECONDARY}>
                Explore
              </Button>
            </ExploreContainer>
          ) : (
            <Link to={riskLink} />
          )
        ) : (
          riskIcon
        )}
      </div>
    );
  }
)``;

export default MaterialChangeActions;

const ExploreContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;

  ${BaseIcon} {
    margin-top: 1rem;
  }
`;
