import _ from 'lodash';
import { useMemo } from 'react';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { Dropdown } from '@src-v2/components/dropdown';
import { SvgIcon } from '@src-v2/components/icons';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { AcceptRisksModal } from '@src-v2/containers/action-modals/override-risk/accept-modals';
import { IgnoreRisksModal } from '@src-v2/containers/action-modals/override-risk/ignore-modals';
import { OverrideRiskStatusModal } from '@src-v2/containers/action-modals/override-risk/override-risk-status';
import { RevertRisksOverrideModal } from '@src-v2/containers/action-modals/override-risk/revert-override-modals';
import { getHighestRiskLevel } from '@src-v2/data/risk-data';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { pluralFormat } from '@src-v2/utils/number-utils';

export function DismissActionItems({ data, setModal, closeModal }) {
  const risksData = useMemo(() => (_.isArray(data) ? data : [data]), [data]);

  const { handleAcceptRisk, handleIgnoreRisk, handleRevertOverrideRisk } = useDismissActionHandlers(
    risksData,
    setModal,
    closeModal
  );

  return (
    <>
      {risksData.every(riskItem => riskItem.riskLevel === 'Accepted') ? (
        <Dropdown.Item onClick={handleRevertOverrideRisk}>
          <RiskIcon
            riskLevel={getHighestRiskLevel(
              risksData.map(({ ruleRiskLevel }) => ruleRiskLevel.toLowerCase())
            )}
          />
          Return to Original Risk
        </Dropdown.Item>
      ) : (
        <Dropdown.Item onClick={handleAcceptRisk}>
          <SvgIcon name="Accept" />
          Accept {pluralFormat(risksData.length, 'Risk')}
        </Dropdown.Item>
      )}

      {risksData.every(riskItem => riskItem.riskLevel === 'Ignored') ? (
        <Dropdown.Item onClick={handleRevertOverrideRisk}>
          <SvgIcon name="Visible" />
          Un-Ignore risk
        </Dropdown.Item>
      ) : (
        <Dropdown.Item onClick={handleIgnoreRisk}>
          <SvgIcon name="Invisible" />
          Ignore {pluralFormat(risksData.length, 'Risk')}
        </Dropdown.Item>
      )}
    </>
  );
}

function useDismissActionHandlers(risksData, setModal, closeModal) {
  const { application } = useInject();
  const trackAnalytics = useTrackAnalytics();

  return useMemo(() => {
    return {
      handleAcceptRisk() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Accept Risk',
        });
        if (application.isFeatureEnabled(FeatureFlag.RiskLifeCycle2)) {
          setModal(
            <OverrideRiskStatusModal
              risk={risksData}
              newRiskStatus="Accepted"
              onClose={closeModal}
            />
          );
        } else {
          setModal(<AcceptRisksModal data={risksData} onClose={closeModal} />);
        }
      },
      handleIgnoreRisk() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Ignore Risk',
        });
        if (application.isFeatureEnabled(FeatureFlag.RiskLifeCycle2)) {
          setModal(
            <OverrideRiskStatusModal
              risk={risksData}
              newRiskStatus="Ignored"
              onClose={closeModal}
            />
          );
        } else {
          setModal(<IgnoreRisksModal data={risksData} onClose={closeModal} />);
        }
      },
      handleRevertOverrideRisk() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Unignore Risk',
        });
        setModal(<RevertRisksOverrideModal data={risksData} onClose={closeModal} />);
      },
    };
  }, [setModal, closeModal, risksData]);
}
