import { useCallback, useMemo } from 'react';
import { SvgIcon } from '@src-v2/components/icons';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { OverrideModal } from '@src-v2/containers/action-modals/override-risk/override-modal';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { getHighestRiskLevel } from '@src-v2/data/risk-data';
import { useInject } from '@src-v2/hooks';
import { pluralFormat } from '@src-v2/utils/number-utils';

export function RevertRisksOverrideModal({ data, onClose, ...props }) {
  const { risks, rbac } = useInject();
  const risksCount = data.length;

  const canEdit = rbac.canEdit(resourceTypes.RiskStatus);

  const originalRiskLevel = useMemo(
    () =>
      data.every(riskItem => riskItem.riskLevel === 'Accepted')
        ? getHighestRiskLevel(data.map(riskItem => riskItem.ruleRiskLevel.toLowerCase()))
        : undefined,
    [data]
  );

  const handleRevert = useCallback(async () => {
    const results = await risks.bulkRevertOverrideRisks(data);
    data.forEach(riskItem =>
      risks.modifyRiskLevel(riskItem, results[riskItem.key] ?? riskItem.ruleRiskLevel)
    );
    onClose?.();
  }, [data, onClose, risks]);

  return (
    <OverrideModal
      {...props}
      disabledSubmitButton={!canEdit}
      onSubmit={handleRevert}
      onClose={onClose}
      title={
        originalRiskLevel ? (
          <>
            <RiskIcon riskLevel={originalRiskLevel} />
            <Heading>Return to Original {pluralFormat(risksCount, 'Risk')}</Heading>
          </>
        ) : (
          <>
            <SvgIcon name="Visible" />
            <Heading>Un-Ignore {pluralFormat(risksCount, 'Risk')}</Heading>
          </>
        )
      }>
      <Paragraph>
        {pluralFormat(
          risksCount,
          'This is not an acceptable risk',
          'These are not acceptable risks'
        )}{' '}
        and should be considered in risk score
      </Paragraph>
    </OverrideModal>
  );
}
