import _ from 'lodash';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { VendorStack } from '@src-v2/components/circles';
import { Collapsible } from '@src-v2/components/collapsible';
import { BaseIcon } from '@src-v2/components/icons';
import { Paragraph } from '@src-v2/components/typography';
import { useSuspense } from '@src-v2/hooks';
import { abbreviate, toPercent } from '@src-v2/utils/number-utils';
import { BreakdownFactory } from './breakdown-factory';

export const LearningStatistics = observer(({ dataFetcher, profileKey = null }) => {
  const { data } = useSuspense(dataFetcher, { key: profileKey });

  return (
    <>
      {!data.length ? (
        <Paragraph>Still processing data, please come back later...</Paragraph>
      ) : (
        data.map(stats => (
          <StatisticsRow
            key={stats.key}
            title={
              <>
                <Title>
                  {abbreviate(stats.count)} {stats.title}
                  {_.isNil(stats.trendPercent) || (
                    <Percent>({toPercent(stats.trendPercent, 0, true)})</Percent>
                  )}
                </Title>
                <VendorStack
                  size="xlarge"
                  vendors={stats.providers.map(provider => ({
                    key: provider,
                  }))}
                />
              </>
            }>
            <BreakdownFactory category={stats.key} breakdown={stats.breakdown} />
          </StatisticsRow>
        ))
      )}
      {data.length < 5 && (
        <Footer>
          <Description>
            Add connectors to get more <br /> risk-based context
          </Description>
          <Button to="/connectors/connect" variant="secondary" endIcon="Arrow">
            Add connectors
          </Button>
        </Footer>
      )}
    </>
  );
});

const Footer = styled.footer`
  display: flex;
  margin-top: 5rem;
  justify-content: space-between;
`;

const Description = styled.span`
  margin: auto 0;
`;

const StatisticsRow = styled(Collapsible)`
  border-radius: 1rem;
  box-shadow: 0 0 0 0.25rem var(--color-blue-gray-30);

  &[data-open] {
    background-color: var(--color-blue-gray-15);
    box-shadow: 0 0 0 0 var(--color-blue-gray-15);
  }

  &:not(:last-child) {
    margin-bottom: 3rem;
  }

  ${Collapsible.Head} {
    padding: 4rem 5rem;
  }

  ${Collapsible.Title} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-grow: 1;

    ${BaseIcon} {
      width: 8rem;
      height: 8rem;
    }
  }

  ${Collapsible.Body} {
    padding: 0 5rem;
  }
`;

const Title = styled.div`
  font-size: var(--font-size-xl);
  font-weight: 400;
`;

const Percent = styled.small`
  margin-left: 1rem;
  font-size: var(--font-size-s);
`;
